import { matchPath } from "react-router";
// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  FormGroup,
  Card,
  CardBody,
  Alert,
  Breadcrumb, BreadcrumbItem, NavItem, NavLink
} from "reactstrap";
import profile from "../../assets/images/profile-img.png";
import * as WhiteLable from "../../components/Common/WhiteLable";
import { withNamespaces } from 'react-i18next';

import classnames from "classnames";

import { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Icons
import Parse from "parse";

// import images
import { v4 as uuidv4 } from "uuid";


// import images
const ForgetPasswordNew = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/forgot-password-verification/:emailId",
    exact: true,
    strict: false,
  });

  const match2 = matchPath(props.history.location.pathname, {
    path: "/reset-password-verification/:emailId",
    exact: true,
    strict: false,
  });


  const [email, setEmail] = useState((match || match2) ? (match || match2).params.emailId : "");
  const [otp, setOTP] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");


  const [selectedStage, setSelectedStage] = useState(1);
  const [uuid, setUUID] = useState(uuidv4())
  const [requiredErrorMessage, setRequiredErrorMessage] = useState(undefined);
  const [validErrorMessage, setValidErrorMessage] = useState(undefined);
  const [runningCloudFunction, setRunningCloudFunction] = useState(false)
  const [serverMessage, setServerMessage] = useState(undefined);
  const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(undefined)
  const [activeTab, setactiveTab] = useState(0);
  const [count, setCount] = useState(30);
  const [showResendButton, setShowResendButton] = useState(false);
  const [startTimer, setStartTimer] = useState(false);


  const pathItems = [
    //id must required
    { id: 1, name: "email" },
    { id: 2, name: "otp" },
    { id: 3, name: "password" },
  ]


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (startTimer) {
      const timer = setTimeout(() => {
        setCount(count - 1);
      }, 1000);

      if (count > 0) {
        if (showResendButton) {
          setShowResendButton(false)
        }
      } else {
        setShowResendButton(true)
        return () => clearTimeout(timer);

      }
    }

  }, [startTimer, count])

  const sendOTP = (resend) => {

    if (email == undefined || email.length === 0) {
      setRequiredErrorMessage("Please enter your email")
      return
    }
    if (!validateEmail(email)) {
      setValidErrorMessage("Please enter valid email address")
      return
    }

    let parameters = {}
    parameters.email = email
    parameters.uuid = uuid
    if (resend) {
      setCount(59)
      parameters.resend = resend
    }

    setRunningCloudFunction(true)
    Parse.Cloud.run("function_RequestPasswordResetOTP", parameters).then(
      (result) => {
        setRunningCloudFunction(false)

        if (result === 1) {
          setSelectedStage(2)
          setStartTimer(true)
          setServerMessage(undefined)
        } else if (result === 2) {
          setServerMessage("User does not exists")
        } else if (result === 3) {
          setServerMessage("Invalid email address")
        }
      },
      (error) => {
        setRunningCloudFunction(false)
        setServerMessage("Please try after some time.")
      }
    );


  };

  const verifyOTP = () => {

    if (otp == undefined || otp.length === 0) {
      setRequiredErrorMessage("Please enter OTP")
      return
    }
    if (otp.length !== 6) {
      setValidErrorMessage("Please enter valid OTP")
      return
    }

    let parameters = {}
    parameters.email = email
    parameters.otp = otp
    parameters.uuid = uuid


    setRunningCloudFunction(true)
    Parse.Cloud.run("function_VerifyPasswordResetOTP", parameters).then(
      (result) => {
        setRunningCloudFunction(false)

        if (result === 1) {
          setServerMessage(undefined)
          setSelectedStage(3)
        } else if (result === 2) {
          setServerMessage("OTP expired !!")
        }
      },
      (error) => {
        setRunningCloudFunction(false)

      }
    );
  }
  const showNotificationWithMsg = (title, msg, type) => {
    // store.addNotification({
    //   title: title,
    //   message: msg,
    //   type: type,
    //   insert: "top",
    //   container: "top-right",
    //   animationIn: ["animated", "fadeIn"],
    //   animationOut: ["animated", "fadeOut"],
    //   dismiss: {
    //     duration: 5000,
    //     onScreen: false,
    //   },
    // });
  };

  const createNewPassword = () => {

    if (newPassword == undefined || newPassword.length === 0) {
      setRequiredErrorMessage("Please enter new password")
      return
    }
    if (newPassword.length < 8) {
      setValidErrorMessage("Please enter min 8 characters long password")
      return
    }
    if (confirmNewPassword !== newPassword) {
      setConfirmNewPasswordError("Password does not match")
      return
    }

    let parameters = {}
    parameters.email = email
    parameters.otp = otp
    parameters.password = newPassword
    parameters.uuid = uuid

    setRunningCloudFunction(true)
    Parse.Cloud.run("function_ResetPassword", parameters).then(
      (result) => {
        setRunningCloudFunction(false)

        if (result === 1) {
          setServerMessage(undefined)
          showNotificationWithMsg(
            "Success",
            "Password changed successfully !!",
            "success"
          );
          window.location = "/auth-cover-login";
        } else if (result === 2) {
          setServerMessage("User does not exists")
        } else if (result === 3) {
          setServerMessage("Session expired, please try again !!")
        }
      },
      (error) => {
        console.log("POP" + error)
        setRunningCloudFunction(false)

      }
    );
  }

  const getUIBasedOnSelectedStage = () => {

    let headerText = ""
    let textFieldHeader = ""
    let placeholder = ""
    let tagName = ""
    let buttonName = ""
    let value = ""
    let validationErrorMessage = ""
    let validationRegex = ""
    let featherIconText = ""
    let avFieldType = "text"

    if (selectedStage === 1) {
      headerText = "Please enter your email address to recieve OTP"
      textFieldHeader = "Email Address"
      placeholder = "Enter Email"
      tagName = "email"
      buttonName = "Send"
      value = email
      validationErrorMessage = "E-Mail is not valid!"
      validationRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
      featherIconText = "mail"
    } else if (selectedStage === 2) {
      headerText = "Please enter OTP. which you received via E-mail"
      textFieldHeader = "OTP"
      placeholder = "Enter OTP"
      tagName = "otp"
      buttonName = "Verify"
      value = otp
      validationErrorMessage = "OTP is not valid"
      validationRegex = ""
      featherIconText = "lock"

    } else if (selectedStage === 3) {
      headerText = "Please enter new password"
      textFieldHeader = "New Password"
      placeholder = "Enter New Password"
      tagName = "newPassword"
      buttonName = "Create"
      value = newPassword
      validationErrorMessage = "Password is not valid"
      validationRegex = ""
      featherIconText = "shield"
      avFieldType = "password"



    }

    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-8">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">{props.t('Recover Account!')}</h5>
                          <p>{props.t(headerText)}</p>
                        </div>
                      </Col>
                      <Col className="col-4 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      {WhiteLable.isSanfortApp() ? (
                        <div className="avatar-lg">
                          <span className="avatar-title bg-white">
                            <img
                              src={WhiteLable.getAppLogo()}
                              alt=""
                              className=""
                              height="60"
                              style={{ marginTop: '-2.5cm', marginLeft: '1cm' }}
                            />
                          </span>
                        </div>
                      ) : WhiteLable.isJumpStartApp() ? (
                        <div className="avatar-lg">
                          <span className="avatar-title bg-white">
                            <img
                              src={WhiteLable.getAppLogo()}
                              alt=""
                              className="bg-white p-1"
                              height="60"
                              style={{ marginTop: '-2.5cm', marginLeft: '2cm', border: '2px solid #556ee62e' }}
                            />
                          </span>
                        </div>
                      ) : WhiteLable.isArtklubApp() ? (
                        <div className="avatar-lg">
                          <span className="avatar-title bg-white">
                            <img
                              src={WhiteLable.getAppLogo()}
                              alt=""
                              className="bg-white p-1"
                              height="60"
                              style={{
                                marginTop: '-2.5cm', marginLeft: '2cm', border: '2px solid #556ee62e'
                              }}
                            />
                          </span>
                        </div>

                      ) : (
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={WhiteLable.getAppLogo()}
                              alt=""
                              className="rounded-circle"
                              height="50"
                            />
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="p-2">
                      {props.forgetError && props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {props.forgetError}
                        </Alert>
                      ) : null}
                      {props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {props.forgetSuccessMsg}
                        </Alert>
                      ) : null}

                      <AvForm className="login-form mt-4">
                        <Row>
                          <Col lg="12">

                            <FormGroup className="position-relative">
                              <Label for={tagName}>
                                {textFieldHeader}{" "}
                                <span className="text-danger">*</span>
                              </Label>

                              <AvField
                                type={avFieldType}
                                className="form-control"
                                name={tagName}
                                id={tagName}
                                placeholder={placeholder}
                                value={value}
                                onChange={(e) => {
                                  if (e.target.value.length > 0) {
                                    setRequiredErrorMessage(undefined)
                                  }
                                  if (selectedStage === 1) {
                                    if (!validateEmail(e.target.value)) {
                                    } else {
                                      setValidErrorMessage(undefined)
                                    }
                                    setEmail(e.target.value)
                                  } else if (selectedStage === 2) {
                                    if (e.target.value.length === 6) {
                                      setValidErrorMessage(undefined)
                                    }
                                    setOTP(e.target.value)
                                  } else if (selectedStage === 3) {
                                    if (e.target.value.length > 7) {
                                      setValidErrorMessage(undefined)
                                    }
                                    setNewPassword(e.target.value)
                                  }
                                }}

                              />

                              {selectedStage === 3 && <FormGroup className="position-relative">

                                <Label for="confirmPassword">
                                  {"Confirm Password"}{" "}
                                  <span className="text-danger">*</span>
                                </Label>

                                <AvField
                                  type={avFieldType}
                                  className="form-control"
                                  name={"confirmPassword"}
                                  id={"confirmPassword"}
                                  placeholder={"Confirm New Password"}
                                  value={confirmNewPassword}
                                  onChange={(e) => {

                                    if (newPassword === e.target.value) {
                                      setConfirmNewPasswordError(undefined)
                                    }
                                    setConfirmNewPassword(e.target.value)

                                  }}

                                />
                              </FormGroup>}
                              {requiredErrorMessage && <p className="text-danger p-0 m-0 font-weight-light"><small>{requiredErrorMessage}</small></p>}
                              {validErrorMessage && <p className="text-danger p-0 m-0 font-weight-light"><small>{validErrorMessage}</small></p>}
                              {serverMessage && <p className="text-danger p-0 m-0 font-weight-light"><small>{serverMessage}</small></p>}
                              {confirmNewPasswordError && <p className="text-danger p-0 m-0 font-weight-light"><small>{confirmNewPasswordError}</small></p>}
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            <Button
                              onClick={() => {
                                if (selectedStage === 1) {
                                  sendOTP()
                                } else if (selectedStage === 2) {
                                  verifyOTP()
                                } else if (selectedStage === 3) {
                                  createNewPassword()
                                }
                              }}
                              color="primary"
                              block
                              disabled={runningCloudFunction}
                            >
                              {runningCloudFunction && <i className="bx bx-loader bx-spin"></i>}
                              {runningCloudFunction ? "" : buttonName}
                            </Button>
                            {
                              selectedStage === 2 &&

                              <div className="mt-2">
                                {
                                  showResendButton ? <Label className="border-bottom text-primary" style={{ cursor: 'pointer' }} onClick={() => { sendOTP(true) }}> Resend OTP</Label> :
                                    <Label>Resend OTP in 00:{count}</Label>
                                }


                              </div>
                            }
                          </Col>
                          <div className="mx-auto">
                            <p className="mb-0 mt-3">
                              <small className="text-dark mr-2">
                                Remember your password ?
                              </small>{" "}
                              <Link
                                to="/login"
                                className="text-dark font-weight-bold"
                              >
                                Sign in
                              </Link>
                            </p>
                          </div>

                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    {props.t('Go back to ')}
                    <Link to="/login" className="font-weight-medium text-primary">
                      {props.t('Login')}
                    </Link>
                  </p>
                  <p>
                    © {new Date().getFullYear()} {WhiteLable.getFooterName()}. {WhiteLable.getDesignTag()}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );

  }


  return (
    <React.Fragment>

      {getUIBasedOnSelectedStage()}

    </React.Fragment>
  );

}
export default (withNamespaces()(ForgetPasswordNew));

