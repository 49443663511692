import React, { useEffect, useState } from "react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Badge
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import { getUserTeacher, setSelecetdSchool, getFranchise } from "../../store/actions";
import { connect } from "react-redux";
import * as Constants from "../../Constents";
import * as CommonFunctions from "../Common/CommonFunctions";
import * as Analytics from "../Common/Analytics";

const HeaderApps = (props) => {
  const [userTeacher, setUserTeacher] = useState(undefined);
  const [currentSchool, setCurrentSchool] = useState(undefined);
  const [showFranchiseReport, setShowFranchiseReport] = useState(false);
  const [showRoyaltySetting, setShowRoyaltySetting] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [showIDCard, setShowIdCard] = useState(false);
  const [showInventoryManagement, setShowInventoryManagement] = useState(false);
  const [showIncidentManagement, setShowIncidentManagement] = useState(false);



  // useEffect(() => {
  //   if (Parse.User.current()) {
  //     if (Parse.User.current().attributes.teacherId) {
  //       props.getUserTeacher(Parse.User.current().attributes.teacherId);
  //     }
  //     if (!props.franchise) {
  //       props.getFranchise(Parse.User.current().attributes.franchiseId);
  //     }
  //   }

  // }, [props.success]);


  useEffect(() => {
    if (props.userTeacher) {
      setUserTeacher(props.userTeacher);
    }
  }, [props.userTeacher]);

  useEffect(() => {
    if (props.selectedSchool) {
      setCurrentSchool(props.selectedSchool);
    }
    if (props.selectedSchool && (!props.selectedSchool.attributes.franchiseId || props.selectedSchool.attributes.franchiseId.length == 0)) {
      setShowIdCard(true)
    }
  }, [props.selectedSchool]);


  useEffect(() => {



    if (props.franchise) {
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_REPORTS_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_REPORTS_INDEX] > 0) {
        setShowFranchiseReport(true)
      } else if (showFranchiseReport) {
        setShowFranchiseReport(false)
      }

      // Royalty settings.
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_ROYALTY_SETTING_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_ROYALTY_SETTING_INDEX] > 1) {
        setShowRoyaltySetting(true)
      }
      else if (showRoyaltySetting) {
        setShowRoyaltySetting(false)
      }

      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_ID_CARD_DISABLE_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_ID_CARD_DISABLE_INDEX] > 0) {
        setShowIdCard(false)
      }
      else if (!showIDCard) {
        setShowIdCard(true)
      }

      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 0) {
        setShowInventoryManagement(true)
      }
      else if (showInventoryManagement) {
        setShowInventoryManagement(false)
      }
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_TICKETS_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_TICKETS_INDEX] > 0) {
        setShowIncidentManagement(true)
      }
      else if (showIncidentManagement) {
        setShowIncidentManagement(false)
      }
    }

  }, [props.franchise]);




  function isFeatureAvilable(schoolPermissionIndex, userPermissionIndex) {
    let currentTeacher = userTeacher;
    let isAppModeDirector = false;
    let isAppModeCenterHead = false;
    let returnValue = false;
    if (currentTeacher && currentTeacher.attributes) {
      if (currentTeacher.attributes.Role === 3) {
        isAppModeDirector = true;
      } else if (currentTeacher.attributes.Role === 2) {
        isAppModeCenterHead = true;
      }
    }
    let school = currentSchool;
    let onlyTeacherFetures = [
      Constants.TEACHER_CHILD_DETAILS_INDEX,
      Constants.TEACHER_GROUPS,
      Constants.TEACHER_ENQUIRY,
    ];
    if (
      currentTeacher &&
      currentTeacher.attributes &&
      school &&
      school.attributes
    ) {
      if (currentTeacher.attributes["featurePermissions"] == undefined) {
        currentTeacher.set("featurePermissions", []);
      }
      if (
        school.attributes.featuresList &&
        school.attributes.featuresList.length > schoolPermissionIndex
      ) {
        if (school.attributes.featuresList[schoolPermissionIndex] > 0) {
          if (Parse.User.current().attributes.isSuperUser) {
            returnValue = true;
          } else if (
            currentTeacher.attributes.featurePermissions != undefined &&
            currentTeacher.attributes.featurePermissions.length >
            userPermissionIndex
          ) {
            if (
              currentTeacher.attributes.featurePermissions[
              userPermissionIndex
              ] > 0
            ) {
              returnValue = true;
            } else {
              returnValue = false;
            }
          } else {
            if (
              schoolPermissionIndex == Constants.FEATURE_PAYMENTS_INDEX ||
              schoolPermissionIndex == Constants.FEATURE_EXPENSE_INDEX
            ) {
              if (isAppModeDirector || isAppModeCenterHead) {
                returnValue = true;
              } else {
                returnValue = false;
              }
            } else {
              returnValue = true;
            }
          }
        } else {
          returnValue = false;
        }
      } else if (schoolPermissionIndex === 999) {
        if (Parse.User.current().attributes.isSuperUser) {
          returnValue = true;
        } else if (
          currentTeacher.attributes.featurePermissions != undefined &&
          currentTeacher.attributes.featurePermissions.length >
          userPermissionIndex
        ) {
          if (
            currentTeacher.attributes.featurePermissions[userPermissionIndex] >
            0
          ) {
            if (onlyTeacherFetures.includes(userPermissionIndex)) {
              returnValue = true;
            } else {
              returnValue = false;
            }
          } else {
            returnValue = false;
          }
        }
      }
    }
    return returnValue;
  }

  const featureBasedOnSchoolType = (name, index) => {

    if (currentSchool && currentSchool.attributes.schoolType === 10) {

      let fl = ["Ratings", "Classrooms", "Groups", "Admissions", "LMS", "Diary", "CCTV", "Fee", "Programs", "Calendar", "Expense", "Visitor", "help-videos", "gallery", "Job Notifications", "Notice Board",];
      if (fl.includes(name)) {
        return false;
      }

    }
    return true;
  }

  const checkSamePage = (clickedLink) => {
    let host = window.location.pathname
    return host.startsWith(clickedLink)
  }

  const getHeaderUI = (headerName, headerLink, newWindow, isNew) => {
    return (
      <li>
        <Link
          to={headerLink}
          className=" waves-effect"
          onClick={() => {
            let user = Parse.User.current();
            Analytics.menuFeatureClick(user.id, user.attributes.email, props.userTeacher.attributes.Name, headerName, "topBar", props.selectedSchool.id, props.selectedSchool.attributes.Name)
          }}
          target={newWindow ? "_blank" : ""}
        >
          <span className={checkSamePage(headerLink) ? "text-primary" : ""}>{headerName}</span>
          {isNew && <Badge color='success' className="badge font-size-11 m-1 bx-tada">New</Badge>}
        </Link>
      </li>
    )
  }

  return (
    <React.Fragment>
      <div>
        <Dropdown
          className="dropdown-mega  d-lg-block ml-2"
          isOpen={socialDrp}
          toggle={() => {
            setsocialDrp(!socialDrp);
          }}
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button"

          >
            <i className="bx bx-customize"></i>
          </DropdownToggle>
          <DropdownMenu className="dropdown-megamenu" style={{ maxHeight: `calc(100vh - 150px)`, overflowY: "auto" }}>

            <Row className="justfy-content-center">
              <Col md={2}>
                <h5 className="font-size-14 mt-0 text-primary">{props.t('School')}</h5>
                <ul className="list-unstyled megamenu-list">
                  {props.selectedSchool && (
                    getHeaderUI(props.t("Staff"), "/staff/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && (
                    getHeaderUI(props.t("All Students"), "/student/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && featureBasedOnSchoolType("Classrooms") && (
                    getHeaderUI(props.t("Classrooms"), "/allClasses/" + props.selectedSchool.id)
                  )}
                  {isFeatureAvilable(999, Constants.TEACHER_GROUPS) && featureBasedOnSchoolType("Groups") && (
                    getHeaderUI(props.t("Groups"), "/groupdetail/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && (
                    getHeaderUI(props.t("Archives"), "/archives/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && (
                    getHeaderUI(props.t("Settings"), "/school/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("gallery") && (
                    getHeaderUI(props.t("Gallery"), "/gallery/" + props.selectedSchool.id, false, false)
                  )}
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("noticeboard") && (
                    getHeaderUI(props.t("Notice Board"), "/noticeboard/" + props.selectedSchool.id, false, true)
                  )}
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("help-videos") && (
                    getHeaderUI(props.t("Help Videos"), "/help-videos/" + props.selectedSchool.id,)
                  )}
                </ul>
              </Col>
              <Col md={2}>
                <h5 className="font-size-14 mt-0 text-primary">{props.t('Enquiry')}</h5>
                <ul className="list-unstyled megamenu-list">

                  {props.selectedSchool && featureBasedOnSchoolType("Admissions") && (
                    getHeaderUI(props.t("Admissions"), "/admissions/" + props.selectedSchool.id)
                  )}
                  {isFeatureAvilable(999, Constants.TEACHER_ENQUIRY) && (
                    getHeaderUI(props.t("Enquiry"), "/enquiry/" + props.selectedSchool.id)
                  )}
                  {isFeatureAvilable(Constants.FEATURE_VISITOR_INDEX, Constants.TEACHER_ENQUIRY) && featureBasedOnSchoolType("Visitor") && (
                    getHeaderUI(props.t("Visitors"), "/visitor-management/" + props.selectedSchool.id)


                  )}

                </ul>
                <h5 className="font-size-14 mt-0 text-primary">{props.t('Finance')}</h5>
                <ul className="list-unstyled megamenu-list">

                  {isFeatureAvilable(
                    Constants.FEATURE_PAYMENTS_INDEX,
                    Constants.TEACHER_PAYMENTS_INDEX
                  ) && featureBasedOnSchoolType("Fee") && (
                      getHeaderUI(props.t("Fee Management"), "/payments/" + props.selectedSchool.id)
                    )}
                  {isFeatureAvilable(Constants.FEATURE_PROGRAM_INDEX, Constants.TEACHER_PROGRAMS) && featureBasedOnSchoolType("Programs") && (
                    getHeaderUI(props.t("Programs"), "/programdetail/" + props.selectedSchool.id)
                  )}
                  {isFeatureAvilable(
                    Constants.FEATURE_EXPENSE_INDEX,
                    Constants.TEACHER_EXPENSE_INDEX
                  ) && featureBasedOnSchoolType("Expense") && (
                      getHeaderUI(props.t("Expense Management"), "/expenses/" + props.selectedSchool.id)
                    )}
                </ul>
              </Col>
              <Col md={2}>
                <h5 className="font-size-14 mt-0 text-primary">{props.t('Learning')}</h5>
                <ul className="list-unstyled megamenu-list">

                  {isFeatureAvilable(
                    Constants.FEATURE_ACTIVITY_TRACKING_INDEX,
                    Constants.TEACHER_CHILD_DEVELOPMENT_INDEX
                  ) && featureBasedOnSchoolType("LMS") && (
                      getHeaderUI(props.t("LMS"), "/lms-curriculum/" + props.selectedSchool.id)
                    )}
                  {isFeatureAvilable(
                    Constants.FEATURE_COURSE,
                    Constants.TEACHER_COURSE
                  ) && (
                      getHeaderUI(props.t("Courses"), "/allcourses/" + props.selectedSchool.id)
                    )
                  }
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Exams") && (
                    getHeaderUI(props.t("Exams"), "/exams/" + props.selectedSchool.id)
                  )}
                </ul>
                <h5 className="font-size-14 mt-0 text-primary">{props.t('Reports')}</h5>
                <ul className="list-unstyled megamenu-list">
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Reports") && (
                    getHeaderUI(props.t("Reports"), currentSchool && currentSchool.attributes.schoolType === 10 ? `/course-reports/${props.selectedSchool.id}` : `/reports/${props.selectedSchool.id}`)
                  )}
                  {props.selectedSchool && props.selectedSchool.id && showFranchiseReport && (
                    getHeaderUI(props.t("Franchise Reports"), `/custom-reports-view/${props.selectedSchool.id}`)
                  )}
                </ul>
              </Col>
              <Col md={2}>
                <h5 className="font-size-14 mt-0 text-primary">{props.t('Utilities')}</h5>
                <ul className="list-unstyled megamenu-list">
                  {props.selectedSchool && featureBasedOnSchoolType("Calendar") && (
                    getHeaderUI(props.t("Calendar"), "/calendar/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Diary") && (
                    getHeaderUI(props.t("Daycare Diary"), "/diaryview/" + props.selectedSchool.id)
                  )}
                  {isFeatureAvilable(
                    Constants.FEATURE_CCTV_INDEX,
                    Constants.TEACHER_CCTV_INDEX
                  ) && featureBasedOnSchoolType("CCTV") && (
                      getHeaderUI(props.t("CCTV"), "/cameradetail/" + props.selectedSchool.id)
                    )}

                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Forms") && (
                    getHeaderUI(props.t("Forms"), "/forms/" + props.selectedSchool.id)
                  )}
                  {props.selectedSchool && showInventoryManagement && (
                    getHeaderUI(props.t("School Kits"), "/inventory-products/" + props.selectedSchool.id, false, false)
                  )}

                  {props.selectedSchool && showIncidentManagement && (
                    getHeaderUI(props.t("Service Desk"), "/all-tickets/" + props.selectedSchool.id, false, false)
                  )}
                  {props.selectedSchool && props.selectedSchool.id && featureBasedOnSchoolType("Job Notifications") && (
                    getHeaderUI(props.t("Job Notifications"), "/job-list/" + props.selectedSchool.id, false, true)
                  )}
                </ul>
              </Col>
              <Col md={2}>
                <h5 className="font-size-14 mt-0 text-primary">{props.t("Utilities")}</h5>
                <ul className="list-unstyled megamenu-list">
                  {props.selectedSchool && showIDCard && (
                    getHeaderUI(props.t("Staff ID Cards"), "/staffIdcard/" + props.selectedSchool.id, true)
                  )}
                  {props.selectedSchool && showIDCard && (
                    getHeaderUI(props.t("Student ID Cards"), "/studentIdcard/" + props.selectedSchool.id, true)
                  )}
                  {props.selectedSchool && (
                    getHeaderUI(props.t("Escorts ID Cards"), "/escortIdcard/" + props.selectedSchool.id, true, false)
                  )}
                  {props.selectedSchool && (
                    getHeaderUI(props.t("Certificates"), "/certificates/" + props.selectedSchool.id, false, false)
                  )}
                  {props.selectedSchool && (
                    getHeaderUI(props.t("Story Cards"), "/all-cards/" + props.selectedSchool.id, false, false)
                  )}
                  {window.location.host.startsWith('localhost') && props.selectedSchool && (
                    getHeaderUI(props.t("Marks Card"), "/all-exams/" + props.selectedSchool.id, false, false)
                  )}
                </ul>
              </Col>
            </Row>
          </DropdownMenu>
        </Dropdown>


      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { userTeacher } = state.Login;
  const { franchise } = state.Franchise;

  return { selectedSchool, userTeacher, franchise };
};

// export default withRouter(withNamespaces()(SidebarContent));
export default connect(mapStatetoProps, { getUserTeacher, setSelecetdSchool, getFranchise })(
  withRouter(withNamespaces()(HeaderApps))
);
