import React, { useEffect, useReducer, useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import Moment from "moment";
import DatePicker from "react-datepicker";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import * as Constants from "../../Constents";
import * as ReduxUtil from "../../store/ReduxUtil"
import SweetAlert from "react-bootstrap-sweetalert";
import Editable from "react-bootstrap-editable";
import { Row, Col, Card, CardBody, Table, Label, Button, Input, Pagination, PaginationItem, PaginationLink, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup } from "reactstrap";
import { setSelecetdSchool, toggleLeftmenu, getSchoolsForTools } from "../../store/actions";
import PaginationView from '../../components/Common/PaginationView';
import { toDataURL } from 'qrcode';

const schoolSubscription = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/tools-schools-subscription/:franchiseId",
        exact: true,
        strict: false,
    });

    const franchiseId = (match && match.params.franchiseId) ?? undefined;
    const PAGE_SIZE = 10;

    const acManagerList = [
        { value: 'Vinay G', label: 'Vinay G' },
        { value: 'Mobin', label: 'Mobin' },
        { value: 'Sachin', label: 'Sachin' }
    ];

    const [allSchool, setAllSchool] = useState([])
    const [filteredSchools, setfilteredSchools] = useState([]);
    const [displaySchools, setDisplaySchools] = useState([]);
    const [displayPageSchools, setDisplayPageSchools] = useState([]);


    const [showPopup, setShowPopup] = useState(false);
    const [selectedSchoolPopup, setSelectedSchoolPopup] = useState()
    const [modalSubscriptionDate, setModalSubscriptionDate] = useState(new Date());
    const [modalGracePeriod, setModalGracePeriod] = useState();
    const [showLoginAlert, setShowLoginAlert] = useState(false);
    const [loading, setLoading] = useState(false);

    const [selectedExpiry, setSelectedExpiry] = useState(franchiseId ? 1 : 7)
    const [schools, setShools] = useState('cctv')
    const [showDatePopup, setShowDatePopup] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());


    const [subScriptionEndAfterGrace, setSubScriptionEndAfterGrace] = useState();
    const [subscriptionEndDate, setSubscriptionEndDate] = useState();

    const [currentPage, setCurrentPage] = useState("1");

    const [selectedState, setSelectedState] = useState(undefined);
    const [selectedCity, setSelectedCity] = useState(undefined);
    const [selectedArea, setSelectedArea] = useState(undefined);
    const [allCityList, setAllCityList] = useState([]);
    const [allAreaList, setAllAreaList] = useState([]);
    const [showAcManagerPopup, setShowAcManagerPopup] = useState(false);
    const [selectedSchoolAcManager, setSelectedSchoolAcManager] = useState(null);
    const [acManager, setAcManager] = useState(null);


    useEffect(() => {
        setLoading(true)
        // Parse.Cloud.run("function_getAllSchoolFromTable", {
        //     franchiseId: franchiseId,
        // }).then((results) => {
        //     let nd = []
        //     for (const s of results) {
        //         if (!s.attributes.deleted) {
        //             if (franchiseId) {
        //                 if (s.attributes.franchiseId == franchiseId) {
        //                     nd.push(s)
        //                     setLoading(false)
        //                 }
        //             } else {
        //                 nd.push(s)
        //             }

        //         }
        //     }
        //     setAllSchool(nd);
        //     handleSelectedOption(franchiseId ? 1 : 7, nd)
        // },
        //     (error) => {
        //         console.log(error);
        //     }
        // );

        if (!props.toolsSchools || props.toolsSchools.length == 0) {
            props.getSchoolsForTools();
        }
    }, [props.toolsSchools])

    const dataRefresh = () => {
        setLoading(true)
        props.getSchoolsForTools();
        setDisplayPageSchools([])
    }
    useEffect(() => {
        if (props.toolsSchools && props.toolsSchools.length > 0) {
            let nd = []
            for (const s of props.toolsSchools) {
                if (!s.attributes.deleted) {
                    if (franchiseId) {
                        if (s.attributes.franchiseId == franchiseId) {
                            nd.push(s)
                            setLoading(false)
                        }
                    } else {
                        nd.push(s)
                    }

                }
            }
            setAllSchool(nd);
            handleSelectedOption(franchiseId ? 1 : 7, nd)
        }
    }, [props.toolsSchools])


    const calculateExpiryDate = (daysToAdd) => {
        const currentDate = new Date();
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + parseInt(daysToAdd));
        return newDate;

    };

    function addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const isFeatureEnabled = (school) => {
        return school.attributes.featuresList && school.attributes.featuresList[14] > 0;
    };


    const handleSelectedOption = (selectedExpiry, schools) => {

        let tempAll = schools ?? allSchool;
        if (selectedExpiry == 1) {
            setDisplaySchools(tempAll)
            setfilteredSchools(tempAll)
            return allSchool
        }
        if (schools === 'cctv' || selectedExpiry === 'cctv') {
            const cctvEnabledSchools = props.toolsSchools.filter(school => isFeatureEnabled(school));
            setDisplaySchools(cctvEnabledSchools);
            setfilteredSchools(cctvEnabledSchools);
            return;
        }
        let filteredSchools = [];

        let todayDate = new Date();
        let expiryDate;

        if (selectedExpiry !== 'custom') {
            expiryDate = calculateExpiryDate(selectedExpiry);
        }

        for (const school of tempAll) {
            let subscriptionExpiryDate = school.attributes.subscriptionExpiryDate;

            if (!subscriptionExpiryDate) {
                if (selectedExpiry == "notset") {
                    filteredSchools.push(school)
                }
                continue;
            }

            let gracePeriod = school.attributes.gracePeriod;
            let finalSubscriptionDate = addDays(subscriptionExpiryDate, gracePeriod);

            if (selectedExpiry === 'custom') {
                if (finalSubscriptionDate >= fromDate && finalSubscriptionDate <= toDate) {
                    filteredSchools.push(school)
                }
            } else if (selectedExpiry == 7) {
                if (finalSubscriptionDate > todayDate && finalSubscriptionDate <= expiryDate) {
                    filteredSchools.push(school)
                }
            } else if (selectedExpiry == 15) {
                if (finalSubscriptionDate > todayDate && finalSubscriptionDate <= expiryDate) {
                    filteredSchools.push(school)
                }
            } else if (selectedExpiry == 30) {
                if (finalSubscriptionDate > todayDate && finalSubscriptionDate <= expiryDate) {
                    filteredSchools.push(school);
                }
            } else if (selectedExpiry == 2) {
                if (finalSubscriptionDate < todayDate) {
                    filteredSchools.push(school);
                }
            } else if(schools === 'cctv'){ 
                if (isFeatureEnabled(school)) {
                    filteredSchools.push(school);
                }
            }
        }

        setDisplaySchools(filteredSchools)
        setfilteredSchools(filteredSchools)
        console.log("DisplaySchools",filteredSchools)
        console.log("FilteredSchool",filteredSchools)
        setLoading(false)
        // console.log("AllSchools1", allSchool)
        // console.log("AllSchools2", props.toolsSchools)
    }

    const filterSchools = (stCode, city, area, search) => {
        // let stCode = document.getElementById("state");
        // let city = document.getElementById("city");
        // let area = document.getElementById("area");
        search = search ?? document.getElementById("search").value
        let displayList = [];
        if (stCode && city && area) {

            let filterS = filteredSchools.filter(function (item) {
                return (item.attributes.areaCode === stCode);
            });

            let filterC = filterS.filter(function (item) {
                return (item.attributes.city === city);
            });
            let filterA = filterC.filter(function (item) {
                return (item.attributes.areaCode === stCode);
            });

            displayList = [...filterA];
        } else if (stCode && city) {

            let filter1 = filteredSchools.filter(function (item) {
                return (item.attributes.areaCode === stCode);
            });

            let filter = filter1.filter(function (item) {
                return (item.attributes.city === city);
            });

            if (filter && filter.length > 0) {
                let arealist = [];
                for (const s of filter) {
                    if (s.attributes.locality && !arealist.includes(s.attributes.locality)) {
                        arealist.push(s.attributes.locality);
                    }
                }
                setAllAreaList(arealist);

            }
            displayList = [...filter]

        } else if (stCode && stCode != "all_state") {
            let filter = filteredSchools.filter(function (item) {
                return (item.attributes.areaCode === stCode);
            });

            if (filter && filter.length > 0) {
                let citylist = [];
                for (const s of filter) {
                    if (!citylist.includes(s.attributes.city)) {
                        citylist.push(s.attributes.city);
                    }

                }
                setAllCityList(citylist)
            }
            displayList = [...filter]
        } else {
            displayList = [...filteredSchools]
        }

        if (search) {
            searchData(search, displayList)
        } else {
            setDisplaySchools(displayList);
        }
    }


    function searchData(search, displayList) {
        const tempList = displayList && displayList.length > 0 ? [...displayList] : [...filteredSchools];
        if (search === undefined || search === null || search.length === 0) {
            setDisplaySchools(tempList);
        } else if (tempList) {
            let filter = tempList.filter((school) => {
                let searchString = school.attributes.Name + " " + school.attributes.EMail + " " + school.attributes.city;
                return searchString.toLowerCase().includes(search.toLowerCase()) || search === school.id;
            });
            setDisplaySchools(filter);
        }
    }

    const getDate = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const handleSave = () => {

        Parse.Cloud.run("function_updateSubscriptionExpiry", {
            schoolId: selectedSchoolPopup.id,
            subscriptionExpiryDate: modalSubscriptionDate,
            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            console.log(result)
            let updatedListAll = ReduxUtil.updateParseObjects(allSchool, [result.School]);
            setAllSchool(updatedListAll);
        },
            (error) => {
                console.log(error);
            }
        );
    }

    const saveAcManager = (answer, school) => {
        const School = Parse.Object.extend('School');
        let schoolObj = new School();
        schoolObj = school;
        schoolObj.set('accountManager', answer);
        schoolObj.save().then(
            (result) => {
                console.log(result);
            },
            (error) => {
                console.error('Error saving account manager:', error);
            }
        );
    };

    const handleUnSetAcManager = (school) => {
        const School = Parse.Object.extend('School');
        let schoolObj = school
        schoolObj.unset("accountManager");
        schoolObj.save().then(
            (result) => {
                console.log(result);
                setAcManager(undefined)
            },
            (error) => {
                console.error('Error unsetting account manager:', error);
            }
        );
    };


    const handleGrantAccess = (instituteId) => {

        Parse.Cloud.run("function_getSchoolAdminAccess", {
            instituteId: instituteId,

            gracePeriod: parseInt(modalGracePeriod),
        }).then((result) => {
            // console.log(result)
            setShowLoginAlert(true);


        },
            (error) => {
                console.log(error);
            }
        );
    }

    useEffect(() => {
        let newDate = new Date(modalSubscriptionDate);
        newDate.setDate(newDate.getDate() + parseInt(modalGracePeriod));
        const today = new Date();
        const diffTime = newDate.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
        setSubScriptionEndAfterGrace(diffDays);

        setSubscriptionEndDate(newDate);

    }, [modalSubscriptionDate, modalGracePeriod])

    const getFormattedDate = (date) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    };

    console.log(acManager)
    console.log("Schools", props.toolsSchools)
    console.log("School ID:", props.schoolId); 
    useEffect(() => {
        let is = (parseInt(currentPage) - 1) * PAGE_SIZE;
        let ie = (parseInt(currentPage) * PAGE_SIZE) - 1;
        if (ie > displaySchools.length) {
            ie = displaySchools.length
        }
        if (ie != 0) {
            let tempSchools = [];
            for (let i = is; i <= ie; i++) {
                if (displaySchools.length > i) {
                    tempSchools.push(displaySchools[i]);
                }
            }
            setDisplayPageSchools(tempSchools)
        } else {
            setDisplayPageSchools([]);
        }

    }, [displaySchools, currentPage]);

    return (
        <div className='page-content  w-100 '  >
            <Modal isOpen={showAcManagerPopup} scrollable={true}>
                <ModalHeader isOpen={showAcManagerPopup}>
                    <div className="modal-title mt-0">Select Account Manager</div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    {acManagerList.map((item, i) => (
                        <div
                            className="custom-control custom-checkbox mb-2 ml-3"
                            key={item.value}
                        >
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id={item.value}
                                onChange={() => { setAcManager(item.value) }}
                                checked={item.value == acManager}
                            />
                            <label
                                className="custom-control-label m-1"
                                onClick={() => { setAcManager(item.value); }}
                            >
                                {item.label}
                            </label>
                        </div>
                    ))}
                    {acManager && (
                        <div className="custom-control custom-checkbox mb-2 ml-3">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                value={"rc"}
                                onChange={() => { setAcManager('rc') }}
                                checked={acManager == 'rc'}
                            />
                            <label
                                className="custom-control-label m-1"
                                onClick={() => { setAcManager('rc') }}
                            >
                                Remove
                            </label>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <div>
                        <button
                            type="button"
                            className="btn btn-secondary m-1"
                            onClick={() => {
                                setShowAcManagerPopup(false);
                            }}
                        >
                            {props.t("Close")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary m-1"
                            onClick={() => {
                                if (acManager == 'rc') {
                                    handleUnSetAcManager(selectedSchoolAcManager);
                                } else {
                                    saveAcManager(acManager, selectedSchoolAcManager);
                                }
                                setShowAcManagerPopup(false);
                            }}
                        >
                            {props.t("Save")}
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
            {showLoginAlert && (
                <SweetAlert
                    success
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Login!!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="primary"
                    title="Success!"
                    onCancel={() => {
                        setShowLoginAlert(false);
                    }}
                    onConfirm={() => {
                        window.open('https://dashboard.liltriangle.com/login', '_blank');
                    }}
                >
                    <div>
                        {" "}
                        {props.t("There you go access granted")}
                    </div>
                </SweetAlert>
            )}
            <div>
                <Row>
                    <Col lg={5} md={6} xs={12}>
                        <div className="search-box position-relative" style={{ width: "75%" }} >
                            <Input
                                type="text"
                                // htmlFor="search"
                                id="search"
                                className="form-control border-1 mb-3 search-input"
                                placeholder="Search..."
                                onChange={(e) => {
                                    filterSchools(selectedState, selectedCity, selectedArea, e.target.value);
                                }}
                            />
                            <i
                                className="bx bx-search-alt search-icon"
                                style={{
                                    fontSize: "20px",
                                    color: "blue"
                                }}
                            ></i>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <div className="float-right">
                            <p className="text-success" style={{ cursor: "pointer" }} onClick={dataRefresh}>
                                <u>
                                    <i className="bx bx-refresh font-size-16 align-middle"></i>
                                    Refresh
                                </u>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <Card style={{ height: "90px" }}>
                    <CardBody>
                        <Row data-repeater-list="outer-group" className="outer" >
                            <Col >
                                <Label className="mb-0">Subscription Expiry</Label>
                                <select
                                    className="form-control"
                                    value={selectedExpiry}
                                    disabled={loading}
                                    onChange={(e) => {
                                        setSelectedState('all_state');
                                        setSelectedCity(undefined);
                                        setSelectedArea(undefined);
                                        setAllCityList([])
                                        setAllAreaList([])
                                        const searchInputElem = document.getElementById('search');
                                        if (searchInputElem) {
                                            searchInputElem.value = '';
                                        }
                                        if (e.target.value === "custom") {
                                            setShowDatePopup(true);
                                        } else {
                                            setSelectedExpiry(e.target.value)
                                            handleSelectedOption(e.target.value);
                                        }
                                    }}>
                                    <option value={1}>All schools</option>
                                    <option value={2}>Subscription Expired</option>
                                    <option value={7} >Subscription ends in 7 days</option>
                                    <option value={15}>Subscription ends in 15 days</option>
                                    <option value={30}>Subscription ends in 30 days</option>
                                    <option value={"notset"}>Subscription not set</option>
                                    <option value="custom">Custom range of days</option>
                                    <option value={"cctv"}>CCTV</option>
                                </select>
                            </Col>
                            <Col lg={3} data-repeater-item className="outer">
                                <Label className="mb-0">State</Label>
                                <select
                                    className="form-control"
                                    id="state"
                                    value={selectedState}
                                    onChange={(e) => {
                                        if (e.target.value === "all_state") {
                                            setSelectedState(undefined);
                                            setSelectedCity(undefined);
                                            setAllCityList([]);
                                            setSelectedArea(undefined);
                                            setAllAreaList([]);
                                            filterSchools(undefined, undefined);
                                        } else {
                                            setSelectedState(e.target.value);
                                            setSelectedCity(undefined);
                                            setAllCityList([]);
                                            setSelectedArea(undefined);
                                            setAllAreaList([]);
                                            filterSchools(e.target.value, undefined);
                                        }
                                        const searchInputElem = document.getElementById('search');
                                        if (searchInputElem) {
                                            searchInputElem.value = '';
                                        }
                                    }}
                                >
                                    <option disabled selected value>-- Pick State --</option>
                                    <option value={"all_state"}>
                                        {"All"}
                                    </option>
                                    {Constants.stateName.map((val, key) => (
                                        <option key={key} value={val.value}>
                                            {val.label}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col lg={3} data-repeater-item className="outer">
                                <Label className="mb-0">City</Label>
                                <select
                                    className="form-control"
                                    id="city"
                                    value={selectedCity}
                                    onChange={(e) => {
                                        if (e.target.value === "all_city") {
                                            setSelectedCity(undefined);
                                            setSelectedArea(undefined);
                                            setAllAreaList([])
                                            filterSchools(selectedState, undefined);

                                        } else {
                                            setSelectedCity(e.target.value);
                                            setSelectedArea(undefined);
                                            setAllAreaList([])
                                            filterSchools(selectedState, e.target.value);

                                        }
                                        const searchInputElem = document.getElementById('search');
                                        if (searchInputElem) {
                                            searchInputElem.value = '';
                                        }
                                    }}
                                >
                                    <option disabled selected value>-- Pick City --</option>
                                    {
                                        <option value={"all_city"}>
                                            {"All"}
                                        </option>
                                    }
                                    {allCityList.map((val, key) => (
                                        <option value={val} key={key}>
                                            {val}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                            <Col lg={3} data-repeater-item className="outer">
                                <Label className="mb-0">Area</Label>
                                <select
                                    className="form-control"
                                    id="area"
                                    value={selectedArea}
                                    onChange={(e) => {
                                        if (e.target.value === "all_area") {
                                            setSelectedArea(undefined);
                                            setSelectedArea(selectedState, selectedCity, undefined)
                                        } else {
                                            setSelectedArea(e.target.value);
                                            filterSchools(selectedState, selectedCity, e.target.value)
                                        }
                                        const searchInputElem = document.getElementById('search');
                                        if (searchInputElem) {
                                            searchInputElem.value = '';
                                        }
                                    }}
                                >
                                    <option disabled selected value>-- Pick Area --</option>
                                    {
                                        <option value={"all_area"}>
                                            {"All"}
                                        </option>
                                    }
                                    {allAreaList.map((val, key) => (
                                        <option value={val} key={key}>
                                            {val}
                                        </option>
                                    ))}
                                </select>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <div className="">
                <Card style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <CardBody>
                        <Row><Col>
                            {(selectedExpiry == 7 || selectedExpiry == 15 || selectedExpiry == 30) && <p><strong>{`${getFormattedDate(new Date())}  -  ${getFormattedDate(calculateExpiryDate(selectedExpiry))}   (${displaySchools.length})`}</strong></p>}
                            {selectedExpiry == 2 && <p><strong>{`Subscription Expired   (${displaySchools.length})`}</strong></p>}
                            {selectedExpiry == 1 || schools === 'cctv' && <p><strong>{`All Schools   (${displaySchools.length})`}</strong></p>}
                            {selectedExpiry == "notset" && <p><strong>{`Subscription not set   (${displaySchools.length})`}</strong></p>}
                            {selectedExpiry == 'custom' && <p><strong>{`${getFormattedDate(fromDate)}  -  ${getFormattedDate(toDate)}   (${displaySchools.length})`}</strong></p>}</Col>
                            <Col>
                                <PaginationView
                                    listSize={displaySchools.length}
                                    pageSize={PAGE_SIZE}
                                    intervalSize={7}
                                    initialInterval={15}
                                    handelCurrentPage={
                                        (cp) => {
                                            setCurrentPage(cp);
                                        }
                                    }
                                />
                            </Col>
                        </Row>
                        <div className="mt-1 ">
                            <div className={"border rounded p-3 mb-1 "} style={{ backgroundColor: "white" }}>
                                <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                    <Col lg={1}>
                                        <div >
                                            <div className="">
                                                <Label className=" font-size-14">
                                                    #
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} >
                                        <div >
                                            <div className="">
                                                <Label className="font-size-14">
                                                    Schools
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div >
                                            <Label className=" font-size-14">
                                                Expiry Date
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className=" font-size-14">
                                                Access
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className=" font-size-14">
                                                Settings
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className=" font-size-14">
                                                Account Manager
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {displayPageSchools.map((school, key) => (
                                <Card className="mb-1 bg-whites" key={school.id}>
                                    <CardBody>
                                        <div >
                                            <Row>
                                                <Col lg={1}>
                                                    <div className="d-flex">
                                                        {key + 1 + ((parseInt(currentPage) - 1) * PAGE_SIZE)}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="pt-1">
                                                        <Link target='_blank' to={'/tools-school-settings/' + school.id}>
                                                            {school.attributes.Name}
                                                        </Link>
                                                        <br />
                                                        <small>{school.id}</small>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="">
                                                        <Row>
                                                            <Col>
                                                                <small>
                                                                    {school.attributes.subscriptionExpiryDate
                                                                        ? getDate(school.attributes.subscriptionExpiryDate)
                                                                        : "--"}
                                                                </small></Col>
                                                            <Col>
                                                                {school.attributes.gracePeriod ? (
                                                                    <small>+{school.attributes.gracePeriod} days</small>
                                                                ) : null}
                                                                {!school.attributes.gracePeriod && <div className=" ">
                                                                    <Label className='text-success ' style={{ cursor: "pointer" }} onClick={() => {
                                                                        setSelectedSchoolPopup(school);
                                                                        setModalSubscriptionDate(school.attributes.subscriptionExpiryDate);
                                                                        setModalGracePeriod(school.attributes.gracePeriod ? school.attributes.gracePeriod : 0)
                                                                        setShowPopup(true)
                                                                    }}>
                                                                        <u>Edit</u>
                                                                    </Label>
                                                                </div>}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col><strong>
                                                                {school.attributes.gracePeriod ? (
                                                                    <p>
                                                                        {getDate(
                                                                            addDays(
                                                                                school.attributes.subscriptionExpiryDate,
                                                                                school.attributes.gracePeriod
                                                                            )
                                                                        )}
                                                                    </p>
                                                                ) : null}</strong>
                                                            </Col>
                                                            <Col>
                                                                {school.attributes.gracePeriod && <div className=" ">
                                                                    <Label className='text-success ' style={{ cursor: "pointer" }} onClick={() => {
                                                                        setSelectedSchoolPopup(school);
                                                                        setModalSubscriptionDate(school.attributes.subscriptionExpiryDate);
                                                                        setModalGracePeriod(school.attributes.gracePeriod ? school.attributes.gracePeriod : 0)
                                                                        setShowPopup(true)
                                                                    }}>
                                                                        <u>Edit</u>
                                                                    </Label>
                                                                </div>}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="mt-1 pt-1 float-right">
                                                        <i
                                                            className="bx bx-lock-open-alt"
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize: "24px",
                                                                color: "#00FF00"
                                                            }}
                                                            onClick={() => {
                                                                handleGrantAccess(school.attributes.instituteId);
                                                            }}
                                                        ></i>
                                                    </div>
                                                </Col>
                                                <Col className='float-right'>
                                                    <div className='float-right'>
                                                        <Link target='_blank' to={'/tools-school-settings/' + school.id}>
                                                            <p className='m-0'><u>settings</u></p>
                                                        </Link>
                                                        {schools === 'cctv' ? (<p className='m-0'><Link to={"/tools-school-settings-cameraDetails/" + school.id}><u>cctvDetails</u></Link></p>) : null}
                                                    </div>
                                                </Col>
                                                <Col className='float-right'>
                                                    <div className='float-right'>
                                                        <Link to={'#'}
                                                            onClick={() => {
                                                                const userEmail = Parse.User.current().attributes.username;
                                                                const users = ["vinay.anna@liltriangle.com", "vinay.g@liltriangle.com", "mobin.eb@liltriangle.com", "sachin.pal@liltriangle.com", "mahesh.naik@liltriangle.com"];
                                                                if (users.includes(userEmail)) {
                                                                    setShowAcManagerPopup(true);
                                                                    setSelectedSchoolAcManager(school);
                                                                    setAcManager(school && school.attributes && school.attributes.accountManager);
                                                                }
                                                            }}>
                                                            <p><u>{school && school.attributes && school.attributes.accountManager ? school && school.attributes && school.attributes.accountManager : "Assign"}</u></p>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))
                            }
                        </div>
                    </CardBody>
                </Card>
            </div>
            <Modal
                size="lg"
                isOpen={showPopup}
                toggle={() => {
                    setShowPopup(false)
                    setSelectedSchoolPopup(undefined)
                    setModalSubscriptionDate(undefined)
                    setModalGracePeriod(undefined)
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setShowPopup(false)
                        setSelectedSchoolPopup(undefined)
                        setModalSubscriptionDate(undefined)
                        setModalGracePeriod(undefined)
                    }}
                >
                    {" "}
                    Subscription date
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Subscription end date :</Label>
                                    <DatePicker
                                        selected={modalSubscriptionDate}
                                        onChange={(date) => {
                                            setModalSubscriptionDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>Grace Period:</Label>
                                    <Input className="form-control"
                                        type="number"
                                        min={0}
                                        id="gracePeriod"
                                        placeholder="Grace Period"
                                        required
                                        value={modalGracePeriod}
                                        onChange={(e) => {
                                            setModalGracePeriod(e.target.value)
                                        }}
                                    />

                                </FormGroup>
                            </Col>

                        </Row>
                        <p className='text-danger'>Subscription Ends in <b>{subScriptionEndAfterGrace ? subScriptionEndAfterGrace : "--"}</b> days, and date is <b>{subscriptionEndDate ? getFormattedDate(subscriptionEndDate) : "--"}</b></p>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            handleSave();
                            setShowPopup(false)
                        }}
                    >
                        <i className="align-middle"></i>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                size="md"
                isOpen={showDatePopup}
                toggle={() => {
                    setShowDatePopup(false)
                }}
            >
                <ModalHeader
                    toggle={() => {
                        setShowDatePopup(false)
                    }}
                >
                    {" "}
                    Select the From and To date
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Row>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>From date :</Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="5">
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label>To date :</Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => {
                                            setToDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={"Select Date"}
                                        dateFormat="dd/MM/yyyy"
                                        showFullMonthYearPicker
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            handleSelectedOption("custom");
                            setSelectedExpiry("custom")
                            setShowDatePopup(false)
                        }}
                    >
                        <i className="align-middle"></i>
                        Apply
                    </button>
                </ModalFooter>
            </Modal>
            {loading && (
                <Col xs="12">
                    <div className="text-center py-4">
                        <Link to="#" className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                            Loading....{" "}
                        </Link>
                    </div>
                </Col>
            )}
            {displayPageSchools.length === 0 && !loading && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Data Found For The Filter")}
                    </h3>
                </Col>
            )}
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { selectedSchool, toolsSchools } = state.School;
    return { selectedSchool, toolsSchools };
};
export default connect(mapStatetoProps, {
    setSelecetdSchool, toggleLeftmenu, getSchoolsForTools,
})(withRouter(withNamespaces()(schoolSubscription)));


