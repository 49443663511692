import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { Col, Card, CardBody, Table, UncontrolledTooltip, Label, Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import toastr from "toastr";
import school from '../../store/model/school/reducer';



const TeacherList = (props) => {
    const [modal, setModal] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState('');

    function getDesignation(teacher) {
        if (teacher.designation) {
            return teacher.designation;
        } else if (teacher.Role === 3) {
            return props.t('Director');
        } else if (teacher.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    function getClassRoomNames(classroomIds) {
        var list = [];
        if (props.classrooms && props.classrooms.length > 0) {
            for (var i in classroomIds) {
                var calssroom = props.classrooms.filter((item) => item.id === classroomIds[i]);
                if (calssroom.length > 0) {
                    list.push({ id: calssroom[0].id, schoolId: calssroom[0].attributes.SchoolID, name: calssroom[0].attributes.Name })
                }
            }

            if (list.length > 0) {
                return list;
            } else {
                return ["No Classrooms"];
            }
        }
        return ["Loading..."]
    }
    function toggleModal(teacher) {
        setSelectedTeacher(teacher);
        setModal(!modal);
    }


    function sendWhatsAppInvite(teacher) {
        const phoneNumber = teacher.attributes.PhoneNumber;
        const teacherName = teacher.attributes.Name;
        const designation = getDesignation(teacher.attributes);
        const schoolName = props.selectedSchool.attributes.Name;
        const message = `Dear ${teacherName},\n\n${schoolName} wants you to install lilTriangle and be more productive as a ${designation}. Click this link to download the app http://onelink.to/liltriangle. Please use this phone number to register.\n\nPowered by lilTriangle`;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl);
    }
    
    function copyAppLink(str, event) {
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
    }

    const pageName = props.schoolType == 10 ? "/coordinators-profile/" : "/staffprofile/";

    return (

        <React.Fragment>
            <Col lg={12}><h4>{props.role}</h4></Col>
            <Col lg={12} >
                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table-centered table-nowrap table-hover">
                                <thead className="thead-light" style={{ height: '0' }}>
                                    <tr>
                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                        <th scope="col">{props.t('Name')}</th>
                                        <th scope="col">{props.t('Email/Phone')}</th>
                                        {props.schoolType!=10 && <th scope="col" style={{ maxWidth: "200px" }}>{props.t('Classrooms')}</th>}
                                        <th scope="col" align='right'>{props.t('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.teachers.map((teacher, i) =>
                                            <tr key={teacher.id} >
                                                <td>
                                                    {
                                                        !teacher.attributes.Photo
                                                            ? <div className="avatar-xs">
                                                                <span className="avatar-title rounded-circle">
                                                                    {teacher.attributes.Name ? teacher.attributes.Name.toUpperCase().charAt(0) : " "}
                                                                </span>
                                                            </div>
                                                            : <div>
                                                                <img className="rounded-circle avatar-xs" src={teacher.attributes.Photo._url} alt="" />
                                                            </div>
                                                    }

                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 mb-1">
                                                        <Link to={teacher.attributes.Role === 3 ? pageName+"d-" + props.schoolId + "/" + teacher.id : pageName + teacher.attributes.SchoolID + "/" + teacher.id}
                                                            className="text-dark">{teacher.attributes.Name}
                                                        </Link>

                                                        {props.schoolType!=10 &&<>{
                                                            teacher.attributes.registered ?
                                                                <i className=" font-size-14 text-success bx bx-check-circle ml-2"
                                                                    id={"installed" + teacher.id}>
                                                                    <UncontrolledTooltip placement="top" target={"installed" + teacher.id}>
                                                                        {props.t('Using App')}
                                                                    </UncontrolledTooltip>
                                                                </i> :
                                                                <i className="font-size-14 text-warning bx bx-navigation ml-2"
                                                                    id={"notinstalled" + teacher.id}
                                                                    onClick={() => toggleModal(teacher)}
                                                                >
                                                                    <UncontrolledTooltip placement="top" target={"notinstalled" + teacher.id}>
                                                                        {props.t('Not Using App, click to send invite!')}
                                                                    </UncontrolledTooltip>

                                                                </i>
                                                        }</>
                                                        }
                                                    </h5>
                                                    <p className="text-muted mb-0">{getDesignation(teacher.attributes)}</p>
                                                </td>
                                                <td>
                                                    {teacher.attributes.EMail && <p className="text-muted mb-0">{teacher.attributes.EMail}</p>}
                                                    {teacher.attributes.PhoneNumber && <p className="text-muted mb-0">{teacher.attributes.PhoneNumber}</p>}


                                                </td>
                                                {props.schoolType!=10 &&<td>

                                                    {teacher.attributes.Role === 1 && <div>
                                                        {
                                                            (teacher.attributes.ClassRooms && teacher.attributes.ClassRooms.length > 0) ?
                                                                <div>
                                                                    {getClassRoomNames(teacher.attributes.ClassRooms).map((classroom, key) =>
                                                                        <div key={key}>
                                                                            <Link to={"/classroom/" + classroom.schoolId + "/" + classroom.id} className="badge badge-soft-primary font-size-11 m-1" key={classroom.id}>{classroom.name}</Link>
                                                                            {(key + 1) % 3 === 0 && <span />}

                                                                        </div>)
                                                                    }
                                                                </div> :
                                                                <Label className="badge badge-soft-primary font-size-11 m-1" >No Classrooms</Label>
                                                        }

                                                    </div>
                                                    }
                                                    {
                                                        (teacher.attributes.Role === 3 || teacher.attributes.Role === 2) &&
                                                        <Label className="badge badge-soft-primary font-size-11 m-1" >All Classrooms</Label>
                                                    }

                                                    {
                                                        (teacher.attributes.Role > 3) &&
                                                        <Label className="badge badge-soft-primary font-size-11 m-1" >No Classrooms</Label>
                                                    }

                                                </td>}

                                                <td>
                                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                                        {/* <li className="list-inline-item px-2">
                                                            <Link to="#" id={"message" + teacher.id}>
                                                                <i className="bx bx-message-square-dots"></i>
                                                                <UncontrolledTooltip placement="top" target={"message" + teacher.id}>
                                                                    {props.t('Message')}
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </li> */}
                                                       { props.schoolType!=10 &&props.showIDCard&&<li className="list-inline-item px-2">
                                                            <Link
                                                                to={"/staffIdcard/" + props.schoolId + "/" + teacher.id} target="_blank" id={"idCard" + teacher.id} >
                                                                <i className="bx bx-id-card"></i>
                                                                <UncontrolledTooltip placement="top" target={"idCard" + teacher.id}>
                                                                    {props.t('ID Card')}
                                                                </UncontrolledTooltip> 
                                                            </Link>
                                                        </li>}
                                                        <li className="list-inline-item px-2">
                                                            <Link
                                                                to={teacher.attributes.Role === 3 ? pageName+"d-" + props.schoolId + "/" + teacher.id : pageName + teacher.attributes.SchoolID + "/" + teacher.id}
                                                                id={"profile" + teacher.id}>
                                                                <i className="bx bx-user-circle"></i>
                                                                <UncontrolledTooltip placement="top" target={"profile" + teacher.id}>
                                                                    {props.t('Profile')}
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </Table>
                        </div>

                    </CardBody>
                </Card>
            </Col>
            <Modal isOpen={modal} toggle={() => toggleModal()}>
            <ModalHeader toggle={() => toggleModal()}>Send Invite</ModalHeader>
            <ModalBody>
                <div>
                    <h5>APP link</h5>
                    <Card className="border-1 p-2">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <a href="http://onelink.to/liltriangle" target="_blank" rel="noopener noreferrer">
                                 http://onelink.to/liltriangle
                            </a>
                            <div
                                id="applink"
                                onClick={() => {
                                    copyAppLink("http://onelink.to/liltriangle");
                                }}
                                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', }}
                            >
                                <i className="bx bxs-copy text-primary"></i>
                                <p style={{ marginLeft: '5px', marginBottom: '0' }}>{props.t("copy")}</p>
                                <UncontrolledTooltip placement="top" target="applink">
                                    {props.t("copy to clipboard")}
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    </Card>
                </div>

                {selectedTeacher &&
                    <div>
                        <p className='m-0'>
                            Are you sure you want to send an invite to {selectedTeacher.attributes.Name}?<br /><br />
                        </p>
                        <Button color="primary" className="m-1" onClick={() => { props.sendInvite(selectedTeacher.id) }}>Invite via SMS/Mail</Button>
                        <Button color="success" onClick={() => {
                            sendWhatsAppInvite(selectedTeacher);
                        }}> <i className="bx bxl-whatsapp"></i>Invite via WhatsApp</Button>
                    </div>
                }
            </ModalBody>
        </Modal>
        </React.Fragment>


    );

}

export default withNamespaces()(TeacherList);