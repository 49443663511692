import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { Container, Row, Col, Button, Card, NavLink, CardBody, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import $ from "jquery";

import Breadcrumb from "../../components/Common/Breadcrumb";
import classnames from "classnames";

import {
  getKidObjectFromLocal,
  getAKid,
  getClassroomsForSchoolId,
  getChildAdditionalDetailsForKidId,
  getSchool,
  getAdmissionQuestions,
  setSelecetdSchool,
  getCorporatePartners,
} from "../../store/actions";
import DetailView from "./detailView";
import OtherDetails from "./otherDetails";
import CoursesComponent from "./CourseComponents/CoursesComponent";
import ShopCartWishListComp from "./ShopCartAndWishList/ShopCartWishListComp";

import COUNTRY_JSON from '../../assets/other/countries.json'

const StudentProfile = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/studentProfile/:schoolId/:kidId",
    exact: true,
    strict: false,
  });
  // const pram = { schoolId : match.params.schoolId , kidId : match.params.kidId}
  const kidId = match.params.kidId;
  const schoolId = match.params.schoolId;
  const [additionalQuestions, additionalQuestionsOps] = useState([]);
  const [currency, currencyOps] = useState("₹");
  const [activeTab, setactiveTab] = useState("1");
  const [showCorporatePartners, setShowCorporatePartners] = useState(false);

  const [showRoyalty, setShowRoyalty] = useState(false);
  const [completeDisable, setCompleteDisable] = useState(false);

  useEffect(() => {
    props.getKidObjectFromLocal(kidId)
    props.getAKid(kidId);
    props.getClassroomsForSchoolId(schoolId);
    props.getChildAdditionalDetailsForKidId(kidId);

    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [kidId, schoolId]);

  useEffect(() => {

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[4] > 0) {
      setShowCorporatePartners(true);
      props.getCorporatePartners(props.franchise.id, "franchiseId")

    } else {
      setShowCorporatePartners(false);
    }

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[2] > 0) {
      setShowRoyalty(true);
      if (props.franchise.attributes.features[2] == 2) {
        setCompleteDisable(true)
      } else {
        setCompleteDisable(false)
      }
    } else {
      setShowRoyalty(false);
      setCompleteDisable(false)
    }
  }, [props.franchise]);
  useEffect(() => {
    if (props.selectedSchool) {
      props.getAdmissionQuestions(
        props.selectedSchool.id,
        props.selectedSchool.attributes.instituteId,
        props.selectedSchool.attributes.franchiseId
      );
      if (props.selectedSchool.attributes.countryCode != "IN") {
        for (const cc of COUNTRY_JSON) {
          if (cc.code == props.selectedSchool.attributes.countryCode) {
            currencyOps(cc.currency.currencySymbol);
            break;
          }
        }
      }
    }
  }, [props.selectedSchool]);
  useEffect(() => {
    let tempQ = [];
    let questionNotToInclude = [
      "Name",
      "Gender",
      "gender",
      "name",
      "DateOfBirth",
    ];
    for (let i in props.adQuestionObj) {
      if (
        !(
          props.adQuestionObj[i].attributes.databaseFieldName in
          questionNotToInclude
        )
      ) {
        let temp = {};
        temp.id = props.adQuestionObj[i].id;
        temp.question = props.adQuestionObj[i].attributes.question;
        temp.dataType = props.adQuestionObj[i].attributes.dataType;
        temp.databaseTableName =
          props.adQuestionObj[i].attributes.databaseTableName;
        temp.questionType = props.adQuestionObj[i].attributes.questionType;
        temp.databaseFieldName =
          props.adQuestionObj[i].attributes.databaseFieldName;
        if (props.kidCAD) {
          temp.answer = props.kidCAD.attributes[
            props.adQuestionObj[i].attributes.databaseFieldName
          ]
            ? props.kidCAD.attributes[
            props.adQuestionObj[i].attributes.databaseFieldName
            ]
            : "";
        }
        if (props.adQuestionObj[i].attributes.choice) {
          temp.options = props.adQuestionObj[i].attributes.choice;
        }
        tempQ.push(temp);
      }
    }
    if (tempQ.length > 0) {
      additionalQuestionsOps(tempQ);
    }
  }, [props.kidCAD, props.adQuestionObj]);

  const getHistoryURL = () => { };

  const updateParentObjectFromChild = (pickNDropObj) => {
    props.getKidObjectFromLocal(kidId)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className='d-flex align-items-start d-print-none'>
            <Col className="d-flex align-items-start">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => { window.history.back() }}>
                <ul className=" list-unstyled">
                  <div className=''>
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>
              <div className=' m-2'>
                <h4>
                  {props.selectedSchool && props.selectedSchool.attributes.schoolType === 10
                    ? "Participant Profile"
                    : props.selectedKid && props.selectedKid.attributes.Deleted
                      ? "Archived Student Profile"
                      : "Student Profile"
                  }
                </h4>
              </div>
            </Col>
          </Row>
          <div>
            {props.selectedKid
              && props.selectedKid.id === kidId &&
              <DetailView
                kidId={kidId}
                schoolId={schoolId}
                selectedSchool={props.selectedSchool}
                kid={props.selectedKid}
                classRooms={props.classrooms}
                showCorporatePartners={showCorporatePartners}
                corporatePartners={props.corporatePartners}
              />

            }
            {props.selectedKid
              && props.selectedKid.id === kidId &&
              (props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) &&
              <OtherDetails
                addQuestions={additionalQuestions}
                kid={props.selectedKid}
                schoolId={schoolId}
                otherDetails={props.kidCAD}
                currency={currency}
                showRoyalty={showRoyalty}
                completeDisable={completeDisable}
                updateParentObjectFromChild={(pickNDropObj) => {
                  updateParentObjectFromChild(pickNDropObj)
                }}
              />
            }
            {props.selectedKid
              && props.selectedKid.id === kidId &&
              (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) &&


              <Card>
                <CardBody>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="bx bx-book" /> {props.t("Courses")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          setactiveTab("2");
                        }}
                      >
                        <i className="bx bx-credit-card-front"></i> {props.t("Shop Cart & Wish List")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1" className="p-3">
                      <CoursesComponent
                        selectedSchool={props.selectedSchool}
                        kidId={props.selectedKid.id}
                      ></CoursesComponent>
                    </TabPane>
                    <TabPane tabId="2" className="p-3">
                      <ShopCartWishListComp
                        selectedSchool={props.selectedSchool}
                        kid={props.selectedKid}
                      ></ShopCartWishListComp>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            }
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedKid } = state.Kid;
  const { classrooms } = state.Classroom;
  const { kidCAD } = state.Kid;
  const { selectedSchool } = state.School;
  const { adQuestionObj } = state.Enquiry;
  const { franchise } = state.Franchise;
  const { corporatePartners } = state.CorporatePartners;
  return { selectedKid, classrooms, kidCAD, selectedSchool, adQuestionObj, franchise, corporatePartners };
};

// export default (withRouter(withNamespaces()(StudentProfile)));
export default connect(mapStatetoProps, {
  getAKid,
  getKidObjectFromLocal,
  getClassroomsForSchoolId,
  getChildAdditionalDetailsForKidId,
  getSchool,
  getAdmissionQuestions,
  setSelecetdSchool,
  getCorporatePartners,

})(withRouter(withNamespaces()(StudentProfile)));
