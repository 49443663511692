import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  Alert,
  Label,
  CardImg, CardText, UncontrolledTooltip,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import {
  getTeachersForSchool,
  getTeacherAttendanceForDay,
  getDaycareAttendanceForDay,
  getClassroomsForSchoolId,
  getSchoolAttendance,
  getAllKids,
  setSelecetdSchool,
  getUserTeacher,
  getEnquiryAnalytics,
  getSchoolAnalytics,
  getEnquiryAnalyticsFor10Days,
  getFranchise,
  getSchoolAnalyticsForSchoolObject
} from "../../store/actions";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import EnquiryColumnChart from "./EnquiryColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import CovidComponent from "./CovidComponent";

import ApexRadial from "./ApexRadial";
import Knob from '../AllCharts/knob/knob';
import PaymentAnalytics from "./PaymentAnalytics";
import EnquiryTrendChart from "../Franchise/EnquiryTrendChart"
import SubscriptionInfoComp from './SubscriptionInfoComp'
import FeesExpenses from "./FeesExpenses";
import SchoolStats from "./SchoolStats";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import Moment from "moment";
// import NewYearImage from '../../assets/images/Posts/NewYear.png'
// import HappyNewYear from '../../assets/images/Posts/HappyNewYear.png'
// import Sankranti from "../../assets/images/Posts/Sankranti.png";
// import RepublicDay from "../../assets/images/Posts/Republic Day.png";
// import HoliHomePage from "../../assets/images/Posts/HoliHomePage.png";
// import UgadiHomePage from "../../assets/images/Posts/ugadiHome.png";
// import RamadhanHomePage from "../../assets/images/Posts/RamadhanHome.png"
// import Teacher_day_Home from "../../assets/images/Posts/teachers_day_main.png"
import ReferSchoolHome from "../../assets/images/Posts/ReferHome _Page.png"
import Ganesh_home from "../../assets/images/Posts/Ganesh_Chaturthi_new.png"
// import GandhiJayanthi from "../../assets/images/Posts/Gandhi.png"
// import DiwaliHome from "../../assets/images/Posts/Diwali_Home.png"
import KannadaHome from "../../assets/images/Posts/Kannada_Home.png"
import ChildrensDayHome from "../../assets/images/Posts/Childrens_day_home.png"

// import RakshaHome from "../../assets/images/Raksha_Home.png"

import JobNotificationHome from "../../assets/images/Posts/JobNotification.png"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import * as Analytics from "../../components/Common/Analytics";

import ReferSchool from './ReferSchool'
import * as WhiteLable from "../../components/Common/WhiteLable"
import { ready } from "jquery";

const Dashboard = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/dashboard/:id",
    exact: true,
    strict: false,
  });
  const schoolId = match && match.params && match.params.id;

  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(undefined);
  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    } else {
      if (props.selectedSchool && props.selectedSchool.attributes) {
        let gracePeriod = props.selectedSchool.attributes.gracePeriod ?? 0;
        let expDate = props.selectedSchool.attributes.subscriptionExpiryDate;
        console.log(gracePeriod)
        console.log(expDate)
        // console.log(gracePeriod, "---expDate: ", expDate, props.selectedSchool)
        if (expDate) {
          let newDate = expDate.setDate(expDate.getDate() + parseInt(gracePeriod));
          // console.log("newDate:" , newDate)
          let today = new Date();
          let diffTime = newDate - today;
          // console.log("T:" + diffTime)
          let diffDays = diffTime / (1000 * 3600 * 24);
          // console.log("D:" + diffDays)
          if (diffDays <= 15) {
            setSubscriptionExpiryDate(newDate);
          }

        }
      }
    }
  }, [props.selectedSchool, schoolId]);
  useEffect(() => {
    if (Parse.User.current()) {
      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId);
      }
    }
  }, [Parse.User.current()]);
  const [enquiryCountList, setEnquiryCountList] = useState([])
  const [admissionCountList, setAdmissionCountList] = useState([])
  const [attendanceList, setAttendanceList] = useState([
    {
      title: "Staff",
      iconClass: "bx-user-circle",
      attendanceCount: "0",
      attendanceStrength: "0",
      attendanceCheckinCout: "0",
      attendanceCheckOutCout: "0",
      attendancePercent: 0,
      color: "#34c38f",
      link: '/reports-staff/'
    },
    {
      title: "Daycare",
      iconClass: "bx-home",
      attendanceCount: "0",
      attendanceStrength: "0",
      attendanceCheckinCout: "0",
      attendanceCheckOutCout: "0",
      attendancePercent: 0,
      color: "#fe1386",
      link: '/reports-daycare/'
    },
    {
      title: "School",
      iconClass: "bx-home",
      attendanceCount: "0",
      attendanceStrength: "0",
      attendanceCheckinCout: "0",
      attendanceCheckOutCout: "0",
      attendancePercent: 0,
      color: "#556ee6",
      link: '/reports-class/'
    },
  ]);
  // console.log(attendanceList)
  const [inCountList, setInCountList] = useState([]);
  const [atSchoolCountList, setAtSchoolCountList] = useState([]);
  const [absentCountList, setAbsentCountList] = useState([]);
  const [totalStrengthList, setTotalStrengthList] = useState([]);
  const [daycareKids, setDaycareKids] = useState([]);
  const [schoolKids, setSchoolKids] = useState([]);
  const [modalReferSchoolOpen, setModalReferSchoolOpen] = useState(false);

  const [
    noAttendanceObjectsFoundBool,
    setNoAttendanceObjectsFoundBool,
  ] = useState(false);

  const [categories, setCategories] = useState([]);
  const [dateListForEnquiryAnalytics, setDateListForEnquiryAnalytics] = useState([])

  const [attendanceClasswiseModal, setAttendanceClasswiseModal] = useState([]);
  const [teacherPaymentRead, teacherPaymentReadOps] = useState(false);



  useEffect(() => {
    if (props.teachers && props.teachers.length > 0) {
      let tempList = [...attendanceList];
      let tempObject = tempList[0];
      tempObject.attendanceStrength = props.teachers.length;
      tempList[0] = tempObject;
      setAttendanceList(tempList);
    }
  }, [props.teachers]);

  useEffect(() => {
    if (schoolId) {
      props.getAllKids(schoolId);
      props.getTeachersForSchool(schoolId);
      if (props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) {
        props.getTeacherAttendanceForDay(schoolId);
        props.getDaycareAttendanceForDay(schoolId);
        props.getClassroomsForSchoolId(schoolId);
        props.getSchoolAttendance(schoolId);
        props.getEnquiryAnalyticsFor10Days(schoolId, "school");
        getEnquiryAnalytics(schoolId, "school");
        props.getSchoolAnalyticsForSchoolObject(schoolId);
      }
    }
  }, [schoolId, props.selectedSchool]);

  useEffect(() => {
    if (
      props.kids &&
      props.kids.length > 0 &&
      props.classrooms &&
      props.classrooms.length > 0
    ) {
      let tempdaycareList = [];
      let tempSchoolList = [];
      let dcClassList = [];
      for (const val of props.classrooms) {
        if (val.attributes.classroomType) {
          dcClassList.push(val.id);
        }
      }
      for (const val of props.kids) {
        if (
          dcClassList.includes(val.attributes.ClassRoomID) ||
          val.attributes.isDaycareChild === 1
        ) {
          tempdaycareList.push(val);
        }
        if (!dcClassList.includes(val.attributes.ClassRoomID)) {
          tempSchoolList.push(val);
        }
      }
      setDaycareKids(tempdaycareList);
      let tempList = [...attendanceList];
      let tempObject = tempList[1];
      tempObject.attendanceStrength = tempdaycareList.length;
      tempList[1] = tempObject;
      setSchoolKids(tempSchoolList);
      let tempObject1 = tempList[2];
      tempObject1.attendanceStrength = tempSchoolList.length;
      tempList[2] = tempObject1;
      setAttendanceList(tempList);
    }
  }, [props.kids, props.classrooms]);

  useEffect(() => {
    if (props.teacherAttendanceObjects && props.teachers) {
      let uniqueObjectList = [];
      let staffAttendanceCount = 0;
      let staffCheckinCout = [];
      for (var i = 0; i < props.teacherAttendanceObjects.length; i++) {
        if (
          !uniqueObjectList.includes(
            props.teacherAttendanceObjects[i].attributes.teacherId
          )
        ) {
          if (
            props.teacherAttendanceObjects[i].attributes.ReachedSchoolAt &&
            !props.teacherAttendanceObjects[i].attributes.LeftSchoolAt
          ) {
            staffAttendanceCount++;
          }

          if (props.teacherAttendanceObjects[i].attributes.ReachedSchoolAt !== undefined) {
            if (!staffCheckinCout.includes(props.teacherAttendanceObjects[i].attributes.teacherId)) {
              staffCheckinCout.push(props.teacherAttendanceObjects[i].attributes.teacherId)
            }

          }
          uniqueObjectList.push(
            props.teacherAttendanceObjects[i].attributes.teacherId
          );
        }
      }
      let tempList = [...attendanceList];
      let tempObject = tempList[0];
      tempObject.attendanceCount = staffAttendanceCount;
      tempObject.attendanceCheckinCout = staffCheckinCout.length;
      tempObject.attendanceCheckOutCout = staffCheckinCout.length - staffAttendanceCount;

      if (props.teachers.length > 0 && staffAttendanceCount > 0) {
        tempObject.attendancePercent = Math.round(
          (staffCheckinCout.length / props.teachers.length) * 100
        );
      } else {
        tempObject.attendancePercent = 0;
      }

      tempList[0] = tempObject;
      setAttendanceList(tempList);
    }
  }, [props.teacherAttendanceObjects, props.teachers]);
  // useEffect(() => {
  //     if (props.attendanceObjects && props.attendanceObjects.length > 0) {
  //         console.log(props.attendanceObjects)

  //     }
  // }, [props.attendanceObjects]);

  useEffect(() => {
    if (props.daycareAttendanceObjects && daycareKids) {
      let attendanceCount = 0;
      let daycareCheckin = [];
      for (var i = 0; i < props.daycareAttendanceObjects.length; i++) {
        if (
          props.daycareAttendanceObjects[i].attributes.checkInTime &&
          !props.daycareAttendanceObjects[i].attributes.checkOutTime
        ) {
          attendanceCount++;
        }

        if (props.daycareAttendanceObjects[i].attributes.checkInTime !== undefined) {
          if (!daycareCheckin.includes(props.daycareAttendanceObjects[i].attributes.kidId)) {
            daycareCheckin.push(props.daycareAttendanceObjects[i].attributes.kidId)
          }

        }
      }
      let tempList = Object.assign([], attendanceList);
      let tempObject = tempList[1];
      tempObject.attendanceCount = attendanceCount;
      tempObject.attendanceCheckinCout = daycareCheckin.length;
      tempObject.attendanceCheckOutCout = daycareCheckin.length - attendanceCount;
      if (daycareKids.length > 0 && attendanceCount > 0) {
        tempObject.attendancePercent = Math.round(
          (daycareCheckin.length / daycareKids.length) * 100
        );
      } else {
        tempObject.attendancePercent = 0;
      }

      tempList[1] = tempObject;
      setAttendanceList(tempList);
    }
  }, [props.daycareAttendanceObjects, daycareKids]);

  const getEnquiryAnalytics = (schoolId) => {
    let filter = {};
    filter.schoolId = schoolId;

    let past20DaysDate = new Date()


    past20DaysDate.setDate(past20DaysDate.getDate() - 20)
    filter.endDate = new Date()
    filter.startDate = past20DaysDate

    props.getEnquiryAnalytics(filter);

  }

  useEffect(() => {

    if (props.enquiryAnalyticsForSchool.length > 0) {

      let tempEnquiryCountList = []
      let tempAdmissionCountList = []
      let tempDateList = []



      for (var i = 0; i < props.enquiryAnalyticsForSchool.length > 0; i++) {


        let analytic = props.enquiryAnalyticsForSchool[i].attributes


        tempEnquiryCountList.push(analytic.newEnquiries ?? 0)
        tempAdmissionCountList.push(analytic.newAdmission ?? 0)
        tempDateList.push(convertDateToString(analytic.enquiryDate))

      }
      setDateListForEnquiryAnalytics(tempDateList)
      setEnquiryCountList(tempEnquiryCountList)
      setAdmissionCountList(tempAdmissionCountList)

    }
  }, [props.enquiryAnalyticsForSchool])

  function convertDateToString(date) {
    return Moment(date).format('DD/MM/YYYY')
  }


  useEffect(() => {
    if (props.classrooms && props.classrooms.length > 0 &&
      props.attendanceObjects && props.attendanceObjects.length > 0 &&
      props.daycareAttendanceObjects && props.daycareAttendanceObjects.length > 0 &&
      props.kids.length > 0) {
      let tempCategories = [];
      let modalObjectsList = [];
      let totalPresent = 0;
      let totalSchoolCheckin = [];
      let tempTotalStrengthList = [];

      for (const val of props.classrooms) {
        if (val.attributes.isDeleted) {
          continue;
        }
        let tempAttendanceObjects = [];
        if (val.attributes.classroomType == 2) {
          for (const attval of props.daycareAttendanceObjects) {
            if (attval.attributes.daycareClassId == val.id) {
              if (attval.attributes.checkInTime) {
                totalPresent += 1;
                if (!attval.attributes.checkOutTime) {
                  if (!totalSchoolCheckin.includes(attval.attributes.kidId)) {
                    totalSchoolCheckin.push(attval.attributes.kidId);
                  }
                }
              }
              tempAttendanceObjects.push(attval);
            }
          }
        } else {
          for (const attval of props.attendanceObjects) {
            if (attval.attributes.Class == val.id) {
              if (attval.attributes.ReachedSchoolAt) {
                totalPresent += 1;
                if (!attval.attributes.LeftSchoolAt) {
                  if (!totalSchoolCheckin.includes(attval.attributes.Kid)) {
                    totalSchoolCheckin.push(attval.attributes.Kid);
                  }
                }
              }
              tempAttendanceObjects.push(attval);
            }
          }
        }

        let modalObject = {};
        modalObject.classroomObject = val;
        modalObject.attendanceObjects = tempAttendanceObjects;
        modalObjectsList.push(modalObject);

        tempCategories.push(val.attributes.Name);

        const strength = props.kids.filter(
          (kid) => kid.attributes.ClassRoomID == val.id || kid.attributes.daycareClassId == val.id
        ).length;
        tempTotalStrengthList.push(strength);
      }

      setAttendanceClasswiseModal(modalObjectsList);

      let tempList = [...attendanceList];
      let tempObject = tempList[2];
      tempObject.attendanceCount = totalSchoolCheckin.length;
      tempObject.attendanceCheckinCout = totalPresent;
      tempObject.attendanceCheckOutCout = totalPresent - totalSchoolCheckin.length;
      if (props.kids.length > 0 && totalPresent > 0) {
        tempObject.attendancePercent = Math.round((totalPresent / props.kids.length) * 100);
      } else {
        tempObject.attendancePercent = 0;
      }
      tempList[2] = tempObject;

      setTotalStrengthList(tempTotalStrengthList);

      let inList = [];
      let atSchoolList = [];
      let absentList = [];
      for (const obj of modalObjectsList) {
        let inCount = 0;
        let atSchoolCount = 0;
        let absentCount = 0;
        for (const attendanceObj of obj.attendanceObjects) {
          if (attendanceObj.attributes.ReachedSchoolAt || attendanceObj.attributes.checkInTime) {
            inCount++;

            if (!attendanceObj.attributes.LeftSchoolAt && !attendanceObj.attributes.checkOutTime) {
              atSchoolCount++;
            }
          } else {
            absentCount++;
          }
        }
        obj.inCount = inCount;
        obj.atSchoolCount = atSchoolCount;
        obj.absentCount = absentCount;

        inList.push(inCount);
        atSchoolList.push(atSchoolCount);
        absentList.push(absentCount);
      }
      setInCountList(inList);
      setAtSchoolCountList(atSchoolList);
      setAbsentCountList(absentList);
      setCategories(tempCategories);
    } else if (
      (props.attendanceObjects && props.attendanceObjects.length === 0) ||
      (props.daycareAttendanceObjects && props.daycareAttendanceObjects.length === 0)
    ) {
      setNoAttendanceObjectsFoundBool(true);
    }
  }, [props.classrooms, props.attendanceObjects, props.daycareAttendanceObjects, props.kids]);


  useEffect(() => {
    if (props.userTeacher) {
      if (props.userTeacher.attributes.featurePermissions) {
        if (props.userTeacher.attributes.featurePermissions[3] === 1) {
          teacherPaymentReadOps(true);
        }
        if (props.userTeacher.attributes.featurePermissions[11] === 1) {
          teacherPaymentReadOps(true);
        }
      }
      if (Parse.User.current().attributes.isSuperUser) {
        teacherPaymentReadOps(true);
      }
    }
  }, [props.userTeacher]);

  let ShowLastMessageAlert = false;

  if (props.schoolAnalyticsForSchoolObject &&
    props.schoolAnalyticsForSchoolObject.attributes &&
    props.schoolAnalyticsForSchoolObject.attributes.lastMessageSent) {
    const today = new Date();
    const lastMessageSentDate = new Date(props.schoolAnalyticsForSchoolObject.attributes.lastMessageSent);
    const timeDifference = today.getTime() - lastMessageSentDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (daysDifference >= 7) {
      ShowLastMessageAlert = true;
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <ReferSchool isOpen={modalReferSchoolOpen} toggleModal={() => { setModalReferSchoolOpen(!modalReferSchoolOpen) }} />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Dashboard")}
          />
          <Row className="">
            {subscriptionExpiryDate && <Col xl="12">
              <SubscriptionInfoComp subscriptionExpiryDate={subscriptionExpiryDate} />
            </Col>}
            <Col xl={props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 ? "12" : "4"}>
              <WelcomeComp schoolId={schoolId} />
              {teacherPaymentRead && props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <PaymentAnalytics schoolId={schoolId} />}
              {false && props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <CovidComponent schoolId={schoolId} />}
              <Row>
                <Col>
                  <SchoolStats kids={props.kids} schoolId={props.selectedSchool && props.selectedSchool.id} />
                </Col>
              </Row>
            </Col>
            {false && <div className="text-center">
              <Card style={{
                backgroundImage: `url('${Teacher_day_Home}')`,
                backgroundSize: '100% 100%',
                height: '6cm'
              }}>
                <CardBody>
                  <Link to={`/new-certificates/${props.selectedSchool.id}/independence-day`} style={{ marginTop: '4cm' }} className="btn btn-primary waves-effect waves-light  bx-tada-low"
                    onClick={() => {
                      let user = Parse.User.current();
                      Analytics.festival_Poster_Click(user.id, user.attributes.email, props.userTeacher.attributes.Name, 'Create Poster', "-", props.selectedSchool.id, props.selectedSchool.attributes.Name)
                    }}>Create Poster for Your School</Link>
                </CardBody>
              </Card>
            </div>}
            {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <Col xl="8">
              <Row>
                <Col xs={12}>
                  {<div className="text-center">
                    <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators={false}>
                      {/* {<div className="text-center">
                        <Card style={{
                          backgroundImage: `url('${ChildrensDayHome}')`,
                          backgroundSize: '100% 100%',
                          height: '6cm'
                        }}>
                          <CardBody>
                            <Link to={`/new-certificates/${props.selectedSchool.id}/childrens-day`} style={{ marginTop: '4.3cm' }} className="btn btn-primary waves-effect waves-light  bx-tada-low"
                              onClick={() => {
                                let user = Parse.User.current();
                                Analytics.festival_Poster_Click(user.id, user.attributes.email, props.userTeacher.attributes.Name, 'Create Poster', "-", props.selectedSchool.id, props.selectedSchool.attributes.Name)
                              }}>Create Poster for Your School</Link>
                          </CardBody>
                        </Card>
                      </div>} */}
                      {!WhiteLable.isAdigurkulApp() && !WhiteLable.isSanfortApp() && !WhiteLable.isJumpStartApp() && !WhiteLable.isArtklubApp() &&
                        <div className="text-center">
                          <Card style={{
                            backgroundImage: `url('${ReferSchoolHome}')`,
                            backgroundSize: '100% 100%',
                            height: '6cm'
                          }}>
                            <CardBody>
                              <Link to={`#`} style={{ marginTop: '4cm', width: '6cm' }} className="btn btn-warning waves-effect waves-light bx-tada-low"
                                onClick={() => {
                                  setModalReferSchoolOpen(true)
                                }}>Refer School</Link>
                            </CardBody>
                          </Card>
                        </div>}
                      <div>
                        <Card style={{
                          backgroundImage: `url('${JobNotificationHome}')`,
                          backgroundSize: '100% 100%',
                          height: '6cm'
                        }}>
                          <CardBody>
                            <Link to={`/Job-list/${props.selectedSchool.id}/`} style={{ marginTop: '4cm' }} className="btn btn-primary waves-effect waves-light">
                              Create Job Post
                            </Link>
                          </CardBody>
                        </Card>
                      </div>
                    </Carousel>
                  </div>}
                </Col>
              </Row>
              {false && <Row>
                <Col xs={12}>

                  <div className="text-center">
                    <Card style={{
                      backgroundImage: `url('${RamadhanHomePage}')`,
                      backgroundSize: '100% 100%',
                      height: '5.4cm'
                    }}>
                      <CardBody>
                        <Link to={`/new-certificates/${props.selectedSchool.id}/new-year`} style={{ marginTop: '3.6cm' }} className="btn btn-primary waves-effect waves-light  bx-tada-low"
                          onClick={() => {
                            let user = Parse.User.current();
                            Analytics.festival_Poster_Click(user.id, user.attributes.email, props.userTeacher.attributes.Name, 'Create Poster', "-", props.selectedSchool.id, props.selectedSchool.attributes.Name)
                          }}>Create Poster for Your School</Link>
                      </CardBody>
                    </Card>
                  </div>
                </Col>
              </Row>}
              <Row>
                <Col lg={12}>
                  {props.kids.length <= 10 && !ShowLastMessageAlert && (
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Alert color="primary" className="">
                          Since it appears that you haven't added any students to the system yet, we recommend booking a demo with the lilTriangle team for further assistance.
                          <br />
                          <div className="mt-3">
                            <Button
                              color="primary"
                              className="btn btn-sm"
                              onClick={() => {
                                window.open("https://calendly.com/liltriangle/liltriangle-help-desk", "_blank");
                              }}
                            >
                              Book Demo
                            </Button>
                          </div>
                        </Alert>
                      </CardBody>
                    </Card>
                  )}
                  {props.kids.length > 10 && ShowLastMessageAlert && (
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Alert color="primary" className="">
                          We haven't detected any activity in the system over the past few days. In order to provide further assistance, we highly recommend booking a demo with the lilTriangle team.
                          <br />
                          <div className="mt-3">
                            <Button
                              color="primary"
                              className="btn btn-sm"
                              onClick={() => {
                                window.open("https://calendly.com/liltriangle/liltriangle-help-desk", "_blank");
                              }}
                            >
                              Book Demo
                            </Button>
                          </div>
                        </Alert>
                      </CardBody>
                    </Card>
                  )}

                  {props.kids.length <= 10 && ShowLastMessageAlert && (
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Alert color="primary" className="">
                          You haven't started using lilTriangle yet. We have amazing features that can help your institute improve efficiency, enhance time management, and much more. Please book a demo with us by clicking the button below.
                          <br />
                          <div className="mt-3">
                            <Button
                              color="primary"
                              className="btn btn-sm"
                              onClick={() => {
                                window.open("https://calendly.com/liltriangle/liltriangle-help-desk", "_blank");
                              }}
                            >
                              Book Demo
                            </Button>
                          </div>
                        </Alert>
                      </CardBody>
                    </Card>
                  )}

                </Col>
              </Row>
              <Row>
                {attendanceList.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <Row className="d-flex justify-content-between m-1">
                          <Col lg={4}>
                            <Label className="text-muted font-weight-medium">
                              {report.title}
                            </Label>
                          </Col>
                          <Col lg={8}>
                            <UncontrolledTooltip placement="top" target={"count" + key}>At School/Checkins/Checkout/Total
                            </UncontrolledTooltip>
                            <Link id={'count' + key} to={`${report.link}${props.selectedSchool.id}`}  ><h5 id='count' className="mb-0">{`${report.attendanceCount}/${report.attendanceCheckinCout}/${report.attendanceCheckOutCout}/${report.attendanceStrength}`}</h5></Link>
                          </Col>
                        </Row>
                        <Row className="d-flex justify-content-between">
                          <Col className="text-center">
                            {report.attendancePercent !== undefined && (
                              <Knob
                                value={report.attendancePercent}
                                height={60}
                                width={60}
                                fgColor={report.color}
                                displayInput={true}
                                disableTextInput={true}
                                onChange={() => { }}
                              />
                            )}
                          </Col>
                        </Row>
                        <div className="d-flex justify-content-end">
                          <Link to={`${report.link}${props.selectedSchool.id}`} className='text-primary'><u>Details</u></Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <CardTitle className="">
                      Attendance (Classrooms)
                    </CardTitle>
                    <Link
                      to={`/reports-classwise/${props.selectedSchool.id}`}
                      className="btn btn-primary waves-effect waves-light btn-sm">
                      Report
                    </Link>
                  </div>
                  <div className="clearfix p-2"></div>
                  {categories.length > 0 && (
                    <StackedColumnChart
                      categories={categories}
                      inCountList={inCountList}
                      atSchoolCountList={atSchoolCountList}
                      absentCountList={absentCountList}
                      totalStrengthList={totalStrengthList}
                    />
                  )}
                </CardBody>
              </Card>
              <Row>
                <Col>
                  {props.enquiryAnalyticsForTenDays && props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <Card>
                    <CardBody>
                      <CardTitle className="mb-4">  Enquiry Trend </CardTitle>
                      {/* {console.log("UI",props.enquiryAnalyticsForTenDays)} */}
                      <EnquiryTrendChart enquiryAnalytics={props.enquiryAnalyticsForTenDays} />
                    </CardBody>
                  </Card>}
                </Col>
              </Row>
              {props.hideButton === false &&
                <FeesExpenses />
              }


              {props.hideButton === false ? (
                <>
                  <div className="clearfix"></div>
                  {dateListForEnquiryAnalytics.length > 0 && (
                    <EnquiryColumnChart
                      categories={dateListForEnquiryAnalytics}
                      inCountList={enquiryCountList}
                      outCountList={admissionCountList}
                    />
                  )}
                </>
              ) : null}
            </Col>}
          </Row>

        </Container>
      </div>

    </React.Fragment>
  );
};

// export default withNamespaces()(Dashboard);

const mapStatetoProps = (state) => {
  const {
    teachers,
    teacherAttendanceObjects,
    daycareAttendanceObjects,
    attendanceObjects,
  } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { userTeacher } = state.Login;
  const { enquiryAnalytics, enquiryAnalyticsForTenDays, enquiryAnalyticsForSchool } = state.EnquiryAnalytics;
  const { selectedSchool, schoolAnalytics, schoolAnalyticsForSchoolObject } = state.School;


  return {
    classrooms,
    attendanceObjects,
    teachers,
    teacherAttendanceObjects,
    daycareAttendanceObjects,
    kids,
    userTeacher,
    enquiryAnalytics,
    selectedSchool,
    enquiryAnalyticsForTenDays,
    schoolAnalytics,
    schoolAnalyticsForSchoolObject,
    enquiryAnalyticsForSchool,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getTeachersForSchool,
      getTeacherAttendanceForDay,
      getDaycareAttendanceForDay,
      getClassroomsForSchoolId,
      getSchoolAttendance,
      getAllKids,
      setSelecetdSchool,
      getUserTeacher,
      getEnquiryAnalytics,
      getSchoolAnalytics,
      getEnquiryAnalyticsFor10Days,
      getFranchise,
      getSchoolAnalyticsForSchoolObject,
    })(Dashboard)
  )
);
