import React, { useEffect, useState } from 'react';
import { Card, CardBody, Label, Button, Badge, Row, Col, Input, InputGroupText, InputGroup, Form, Modal, Nav, NavItem, NavLink, FormGroup, ModalHeader, Table } from 'reactstrap';
import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import Parse from "parse";
import toastr from 'toastr'
import Moment from 'moment';
import 'toastr/build/toastr.min.css'
import { getFranchise, updateSaleOrders, getFranchiseTeachers } from '../../../store/actions';

const ReceivePaymentModal = (props) => {

    const paymentMode = ["Cash", "Cheque", "Online"];

    const [invoiceItems, setInvoiceItems] = useState([]);
    const [reciveTotal, setReciveTotal] = useState(0);
    const [verifyTransaction, setVerifyTransaction] = useState(false);
    const [activeTraType, setActiveTraType] = useState("Cash");
    const [activeTab, setactiveTab] = useState("Cash");
    const [remark, setRemark] = useState("");
    const [cashDepositDate, setCashDepositDate] = useState();
    const [chequeDate, setChequeDate] = useState();
    const [chequeNumber, setChequeNumber] = useState("");
    const [depositReference, setDepositReference] = useState("");
    const [micrCode, setmicrCode] = useState("");
    const [bankName, setBankName] = useState("");
    const [onlineTransactionId, setOnlineTransactionId] = useState("");
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [chequeDateError, setChequeDateError] = useState("");
    const [bankNameError, setBankNameError] = useState("");
    const [chequeNoError, setchequeNoError] = useState("");
    const [showReceivingLoader, setShowReceivingLoader] = useState(false)
    const [PendingAmount, setPendingAmount] = useState()
    const [paidAmount, setPaidAmount] = useState()
    const [moneyTransactions, setMoneyTransactions] = useState([])
    const [showTransactionTable, setShowTransactionTable] = useState(false)


    useEffect(() => {
        if (props.franchiseId) {
            props.getFranchise(props.franchiseId);
        }
    }, [props.franchiseId]);

    useEffect(() => {
        if (props.saleOrder) {
            var MoneyTransactions = Parse.Object.extend("MoneyTransactions");
            var query = new Parse.Query(MoneyTransactions);

            query.equalTo("invoiceId", props.saleOrder.attributes.invoiceId);
            query.notEqualTo("isDeleted", true);
            query.addAscending("order");

            query.find().then((transactions) => {
                if (transactions) {
                    let amount = 0;
                    for (const tr of transactions) {
                        amount += tr.attributes.amount;
                    }
                    setPaidAmount(amount);
                    const orderValue = props.saleOrder ? props.saleOrder.attributes.freightCharges
                        ? parseInt(props.saleOrder.attributes.orderValue) + parseInt(props.saleOrder.attributes.freightCharges)
                        : parseInt(props.saleOrder.attributes.orderValue)
                        : ''
                    const pendingAmount = orderValue - amount;
                    setPendingAmount(pendingAmount);
                }
                setMoneyTransactions(transactions);
            });
        }
    }, [props.saleOrder])

    useEffect(() => {
        props.getFranchiseTeachers();
    }, []);

    const handleSave = () => {

        setShowReceivingLoader(true);

        let InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
        let query = new Parse.Query(InventorySaleOrder);

        query.equalTo("objectId", props.saleOrder.id);
        query.notEqualTo("isDeleted", true);

        query.addAscending("order");
        query.first().then(
            (saleOrder) => {
                // console.log(saleOrder)
                var InventorySaleOrderItem = Parse.Object.extend("InventorySaleOrderItems");
                var query = new Parse.Query(InventorySaleOrderItem);

                query.equalTo("saleOrderId", saleOrder.id)

                query.notEqualTo("isDeleted", true);

                query.addAscending("order");
                query.find().then(
                    (saleOrderItems) => {
                        // console.log(saleOrderItems);
                        createInvoice(saleOrder, saleOrderItems)
                    },
                );

            },
            (error) => {
                // console.log(error);
            }
        );
    }

    const createInvoice = (saleOrder, saleOrderItems) => {

        var Invoice = Parse.Object.extend("Invoice");
        var query = new Parse.Query(Invoice);


        query.equalTo("objectId", saleOrder.attributes.invoiceId);
        query.notEqualTo("isDeleted", true);

        query.first().then(
            (invoice) => {
                // console.log(invoice);

                var InvoiceItems = Parse.Object.extend("InvoiceItems");
                var query = new Parse.Query(InvoiceItems);


                query.equalTo("invoiceId", saleOrder.attributes.invoiceId);
                query.notEqualTo("isDeleted", true);

                query.find(invoiceItems).then(
                    (invoiceItems) => {
                        // console.log(invoiceItems);
                        let object = { invoice: invoice, invoiceItems: invoiceItems, saleOrder: saleOrder };
                        // console.log(object)
                        payNow(object);
                    },
                );
            },
        );
    }

    const payNow = (invoiceObject) => {

        let invoiceItem = [];
        for (const val of invoiceObject.invoiceItems) {
            let temp = {};
            temp.amount = val.attributes.amount;
            temp.discount = val.attributes.discount;
            temp.discountType = val.attributes.discountType;
            temp.id = val.id;

            temp.itemDescription = val.attributes.itemDescription;
            temp.payableAmout = val.attributes.totalAmount;
            temp.tax = val.attributes.tax;
            temp.totalAmount = val.attributes.totalAmount;
            invoiceItem.push(temp);
        }
        let totalOrderValue = parseInt(invoiceObject.saleOrder.attributes.orderValue);
        let paidnPending = parseInt(paidAmount) + parseInt(PendingAmount);
        const amount = "" + parseInt(invoiceObject.invoice.attributes.amount).toFixed(2);
        const submitAmount = parseFloat(amount);

        const MT = Parse.Object.extend("MoneyTransactions");
        let mt = new MT();
        mt.set("invoiceId", invoiceObject.invoice.id);
        mt.set("amount", parseInt(PendingAmount));
        mt.set("transactionType", activeTraType);
        mt.set("deleted", true);
        mt.set("invoiceItems", invoiceItem);
        mt.set("transactionFrom", "INVENTORY");
        mt.set("franchiseId", props.franchiseId);
        mt.set("receivedBy", Parse.User.current().attributes.teacherId)
        mt.set('inventoryBuyerId', invoiceObject.invoice.attributes.recipientId)

        if (activeTraType == "Cheque") {
            mt.set("chequeDate", new Date(chequeDate));
            mt.set("chequeNo", chequeNumber);
            mt.set("micrCode", micrCode);
            mt.set("bankName", bankName);
            mt.set("depositReference", depositReference);
        }
        if (activeTraType == "Online") {
            mt.set("transactionId", onlineTransactionId);
            mt.set("bankName", bankName);
        }
        // console.log(mt)

        mt.save().then((mtResult) => {
            //    console.log(mtResult);
            let saleOrder = invoiceObject.saleOrder
            saleOrder.set("invoiceId", mtResult.attributes.invoiceId);
            if (paidnPending >= totalOrderValue) {
                saleOrder.set("paidStatus", 1);
            } else if (paidnPending > 0) {
                saleOrder.set("paidStatus", 3);
            } else {
                saleOrder.set("paidStatus", 0);
            }

            saleOrder.save().then((saleOrderResult) => {

                let invoice = invoiceObject.invoice
                invoice.set("paidAmount", amount);
                if (paidnPending >= totalOrderValue) {
                    invoice.set("paidStatus", 1);
                } else if (paidnPending > 0) {
                    invoice.set("paidStatus", 3);
                } else {
                    invoice.set("paidStatus", 0);
                }

                invoice.save().then((invoice) => {
                    var InventorySaleOrderLog = Parse.Object.extend("InventorySaleOrderLog");
                    var saleOrderLog = new InventorySaleOrderLog();

                    saleOrderLog.set("saleOrderId", saleOrderResult.id);
                    saleOrderLog.set("logTitle", "Paymant Received");
                    saleOrderLog.set("fromStatus", -1);
                    saleOrderLog.set("toStatus", 0);
                    saleOrderLog.set("createdBy", Parse.User.current().attributes.teacherId);

                    saleOrderLog.save()

                    props.updateSaleOrders([saleOrderResult])
                    toastr.success("Payment Received successfully  and order Placed", { timeOut: 3000 });
                    setShowTransactionTable(false)
                    props.OnCloseIncompleteOrderPopup()
                    setShowReceivingLoader(false)
                    setCashDepositDate('')
                    setRemark("")
                    setDepositReference("")
                    setOnlineTransactionId("")
                    setBankName("")
                    setChequeDate("")
                    setChequeNumber("")
                    setmicrCode("")
                    setDepositReference("")
                })

            })
        });
    };

    const getTeacherName = (teacherId) => {
        if (props.franchiseTeachers && props.franchiseTeachers.length > 0) {
            let temp = props.franchiseTeachers.filter(
                (teacher) => teacher.id == teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    useEffect(() => {
        if (props.schoolId && moneyTransactions && moneyTransactions.length > 0) {
            setShowTransactionTable(true)
        }
    }, [props.schoolId])

    return (
        <React.Fragment>
            <Modal
                isOpen={props.showIncompleteOrderPopup}
                toggle={() => {
                    props.OnCloseIncompleteOrderPopup()
                }}
                // scrollable={true}
                size="lg"
            >
                <ModalHeader
                    toggle={(() => {
                        props.handelClose()
                        setShowTransactionTable(false)
                        props.OnCloseIncompleteOrderPopup()
                    })}>
                    Receive Payment
                </ModalHeader>
                <div className="modal-body">
                    <InputGroup className="mb-1">
                        <InputGroupText
                            addonType="prepend"
                            style={{ minWidth: "80%", maxWidth: "80%" }}
                        >
                            <Label
                                className=" justify-content-start m-2"
                                style={{ minWidth: "100%", maxWidth: "100%", textAlign: "left" }}
                            >
                                Order Amount
                            </Label>
                        </InputGroupText>
                        <Input
                            type="number"
                            style={{ minWidth: "20%", maxWidth: "20%" }}
                            value={
                                props.saleOrder
                                    ? props.saleOrder.attributes.freightCharges
                                        ? props.saleOrder.attributes.orderValue + props.saleOrder.attributes.freightCharges
                                        : props.saleOrder.attributes.orderValue
                                    : ''
                            }
                            className="form-control text-end"
                            disabled={true}
                            onChange={(e) => { }}
                        />
                    </InputGroup>

                    <InputGroup className="mb-1">
                        <InputGroupText
                            addonType="prepend"
                            style={{ minWidth: "80%", maxWidth: "80%" }}
                        >
                            <Label
                                className=" justify-content-start m-2"
                                style={{ minWidth: "100%", maxWidth: "100%", textAlign: "left" }}
                            >
                                {` Paid Amount`}{"   "} {"   "}
                                {!props.showPaymentReceivedModal && <span className='text-success' style={{ fontFamily: "Arial, sans-serif", cursor: 'pointer' }}
                                    onClick={() => {
                                        setShowTransactionTable(true)
                                    }}>
                                    (View Transactions)
                                </span>}

                            </Label>
                        </InputGroupText>
                        <Input
                            type="number"
                            style={{ minWidth: "20%", maxWidth: "20%" }}
                            value={paidAmount}
                            className="form-control text-end"
                            disabled={true}

                        />
                    </InputGroup>
                    <InputGroup className="mb-1">
                        <InputGroupText
                            addonType="prepend"
                            style={{ minWidth: "80%", maxWidth: "80%" }}
                        >
                            <Label
                                className=" justify-content-start m-2"
                                style={{ minWidth: "100%", maxWidth: "100%", textAlign: "left" }}
                            >
                                Pending Amount
                            </Label>
                        </InputGroupText>
                        <Input
                            type="number"
                            style={{ minWidth: "20%", maxWidth: "20%" }}
                            value={PendingAmount}
                            disabled={props.schoolId}
                            className="form-control text-end"
                            onChange={(e) => {
                                setPendingAmount(e.target.value)
                            }}
                        />
                    </InputGroup>

                    {((showTransactionTable || props.showPaymentReceivedModal) && (moneyTransactions && moneyTransactions.length > 0) || props.schoolId) && <Table className="table-bordered table-sm " style={{ width: '100%', marginTop: "25px" }}>
                        <thead>
                            <tr>
                                <th style={{ width: "70px" }}>{props.t("#")}</th>
                                <th>Mode</th>
                                <th>Received Date</th>
                                <th>Received By</th>
                                <th className="text-right">
                                    Amount
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {moneyTransactions.map((val, key) => (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{val.attributes.transactionType}</td>
                                    <td>{Moment(val.attributes.createdAt).format('DD-MMM-YYYY')}</td>
                                    <td>{getTeacherName(val.attributes.receivedBy)}</td>
                                    <td className="text-right">
                                        <span >
                                            {"₹"}
                                        </span>{" "}
                                        {parseFloat(val.attributes.amount).toFixed(2)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>}
                    {(!props.showPaymentReceivedModal && props.franchiseId) && <div className=''>
                        <Nav pills className="bg-light rounded mb-2" role="tablist">
                            <div className="ml-2"></div>
                            {paymentMode.map((val, key) => (
                                <NavItem key={key} className="mt-3 mr-2">
                                    <NavLink
                                        className={classnames({ active: activeTab === val })}
                                        onClick={() => {
                                            setActiveTraType(val);
                                            setactiveTab(val);
                                            val === "Cash"
                                                ? setVerifyTransaction(true)
                                                : setVerifyTransaction(false);
                                        }}
                                    >
                                        {val}
                                    </NavLink>
                                </NavItem>
                            ))}
                            <NavItem className="floar-right ml-auto">
                                <NavLink>
                                    Transaction Date
                                    <DatePicker
                                        selected={paymentDate}
                                        onChange={(date) => {
                                            setPaymentDate(date);
                                        }}
                                        className="form-control"
                                        placeholderText="Selecte Date"
                                        startDate={new Date()}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <FormGroup className="mb-1 mt-3">
                            {activeTraType === "Cash" && (
                                <AvForm className="needs-validation">
                                    <Row>
                                        <Col md="3" xl="3" sm="12">
                                            <FormGroup style={{ marginBottom: "-1rem" }}>
                                                <Label htmlFor="validationCustom01">
                                                    Cash Deposit Date
                                                </Label>
                                                <AvField
                                                    name="firstname"
                                                    placeholder="Cheque Date"
                                                    type="Date"
                                                    className="form-control"
                                                    value={cashDepositDate}
                                                    onChange={(e) => {
                                                        setCashDepositDate(e.target.value);
                                                    }}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                            )}
                            {activeTraType === "Cheque" && (
                                <AvForm className="needs-validation">
                                    <Row>
                                        <Col md="3" xl="3" sm="12">
                                            <FormGroup style={{ marginBottom: "-1rem" }}>
                                                <Label htmlFor="validationCustom01">
                                                    Date
                                                </Label>
                                                <AvField
                                                    name="firstname"
                                                    placeholder="Cheque Date"
                                                    type="Date"
                                                    className="form-control"
                                                    value={chequeDate}
                                                    onChange={(e) => {
                                                        setChequeDate(e.target.value);
                                                        setChequeDateError("");
                                                    }}

                                                />
                                            </FormGroup>
                                            <small className="text-danger mt-0">
                                                {chequeDateError}
                                            </small>
                                        </Col>
                                        <Col md="2" xl="2" sm="12">
                                            <FormGroup style={{ marginBottom: "-1rem" }}>
                                                <Label htmlFor="validationCustom02">
                                                    Number
                                                </Label>
                                                <AvField
                                                    name="lastname"
                                                    placeholder="Cheque Number"
                                                    type="text"
                                                    className="form-control"
                                                    value={chequeNumber}
                                                    onChange={(e) => {
                                                        setChequeNumber(e.target.value);
                                                        setchequeNoError("");
                                                    }}

                                                />
                                            </FormGroup>
                                            <small className="text-danger mt-0">
                                                {chequeNoError}
                                            </small>
                                        </Col>
                                        <Col md="2" xl="2" sm="12">
                                            <FormGroup>
                                                <Label htmlFor="validationCustom03">
                                                    MICR Code
                                                </Label>
                                                <AvField
                                                    name="MICR Code"
                                                    placeholder="MICR Code"
                                                    type="text"
                                                    className="form-control"
                                                    value={micrCode}
                                                    onChange={(e) => setmicrCode(e.target.value)}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" xl="2" sm="12">
                                            <FormGroup style={{ marginBottom: "-1rem" }}>
                                                <Label htmlFor="validationCustom04">
                                                    Bank Name
                                                </Label>
                                                <AvField
                                                    name="Bank Name"
                                                    placeholder="Bank Name"
                                                    type="text"
                                                    className="form-control"
                                                    value={bankName}
                                                    onChange={(e) => {
                                                        setBankName(e.target.value);
                                                        setBankNameError("");
                                                    }}

                                                />
                                            </FormGroup>
                                            <small className="text-danger mt-0">
                                                {bankNameError}
                                            </small>
                                        </Col>
                                        <Col md="3" xl="3" sm="12">
                                            <FormGroup>
                                                <Label htmlFor="validationCustom04">
                                                    Deposit Reference
                                                </Label>
                                                <AvField
                                                    name="Deposit Reference"
                                                    placeholder="Deposit Reference"
                                                    type="text"
                                                    className="form-control"
                                                    value={depositReference}
                                                    onChange={(e) =>
                                                        setDepositReference(e.target.value)
                                                    }

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                            )}
                            {activeTraType === "Online" && (
                                <AvForm className="needs-validation mb-1 mt-3">
                                    <Row>
                                        <Col md="6" xl="6" sm="12">
                                            <FormGroup>
                                                <Label htmlFor="validationCustom01">
                                                    Reference Number
                                                </Label>
                                                <AvField
                                                    name="firstname"
                                                    placeholder="Online Reference Number"
                                                    type="text"
                                                    className="form-control"
                                                    value={onlineTransactionId}
                                                    onChange={(e) =>
                                                        setOnlineTransactionId(e.target.value)
                                                    }

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6" xl="6" sm="12">
                                            <FormGroup>
                                                <Label htmlFor="validationCustom02">
                                                    Bank Name
                                                </Label>
                                                <AvField
                                                    name="lastname"
                                                    placeholder="Bank Name"
                                                    type="text"
                                                    className="form-control"
                                                    value={bankName}
                                                    onChange={(e) => setBankName(e.target.value)}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                            )}
                        </FormGroup>
                    </div>}
                    {(!props.showPaymentReceivedModal && props.franchiseId) && <div className='py-2'>
                        <Label>
                            Add remark about how and when it was paid:
                        </Label>
                        <Input
                            type="textarea"
                            id="textarea"
                            onChange={(e) => {
                                setRemark(e.target.value);
                            }}
                            maxLength="225"
                            rows="3"
                            placeholder="Remark."
                            style={{ resize: "none" }}
                            value={remark}
                        />
                    </div>}
                </div>
                {(!props.showPaymentReceivedModal && props.franchiseId) && <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            props.OnCloseIncompleteOrderPopup()
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                    {showReceivingLoader ?

                        <Button type="button" color="primary" className="btn btn-primary waves-effect waves-light">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>Receiving...
                        </Button> :
                        <Button type="submit" className="btn btn-primary waves-effect waves-light"
                            color="primary"
                            onClick={(() => {
                                handleSave()
                            })}>
                            Receive
                        </Button>}
                </div>}
            </Modal>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { franchise } = state.Franchise;
    const { franchiseTeachers } = state.Teacher;

    return { franchise, franchiseTeachers };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, { getFranchise, updateSaleOrders, getFranchiseTeachers })(ReceivePaymentModal))); 