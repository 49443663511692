import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Label, Form, FormGroup, Input, Table, Button, Collapse } from "reactstrap";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from "moment";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    setSelecetdSchool, getInventoryVendorForOwnerId, getInventoryItemForOwnerId, setSelectedPurchaseOrder,
    setSelectedPurchaseOrderItems, getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds
} from '../../../store/actions';

function NewPurchaseOrder(props) {

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/new-purchase/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatchAdd = matchPath(props.history.location.pathname, {
        path: "/franchise-new-purchase/:franchiseId",
        exact: true,
        strict: false,
    });

    const matchEditItem = matchPath(props.history.location.pathname, {
        path: "/edit-inventory-purchase-order/:schoolId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatchEditItem = matchPath(props.history.location.pathname, {
        path: "/franchise-edit-purchase-order/:franchiseId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const match = matchEditItem ?? franchiseMatchEditItem;
    const purchaseOrderId = match && match.params.purchaseOrderId;

    const match1 = matchAdd ?? matchEditItem
    const schoolId = match1 && match1.params.schoolId;

    const match2 = franchiseMatchAdd ?? franchiseMatchEditItem;
    const franchiseId = match2 && match2.params.franchiseId;


    const [tableData, setTableData] = useState((franchiseMatchAdd || matchAdd) ? [{
        itemId: uuidv4(),
        item: undefined,
        units: '',
        quantity: 0,
        price: 0,
        tax: 0,
        finalPrice: 0,
        quantityError: false,
        priceError: false,
        itemError: false,
        dropdownItem: undefined,
        subItems: [],
    }] : []);

    const [vendorList, setVendorList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [vendor, setVendor] = useState();
    const [date, setDate] = useState(formatDate(new Date()));
    const [vendorListError, setVendorListError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [isDisabled, setIsDesabled] = useState(false);
    const [approveAlert, setApproveAlert] = useState(false);
    const [saveLoding, setSaveLoding] = useState(false);
    const [showItemMatrix, setShowItemMatrix] = useState(false);
    const [purchaseOrderCost, setPurchaseOrderCost] = useState(0);


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);


    useEffect(() => {
        if (schoolId) {
            props.getInventoryVendorForOwnerId(schoolId, 'school')
            // props.getInventoryItemForOwnerId(schoolId, 'school')
            props.getInventoryWarehouseForOwnerId(schoolId, 'school');
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryVendorForOwnerId(franchiseId, 'franchise')
            props.getInventoryItemForOwnerId(franchiseId, 'franchise')
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);



    useEffect(() => {
        if (purchaseOrderId) {
            props.setSelectedPurchaseOrder(purchaseOrderId);
            props.setSelectedPurchaseOrderItems(purchaseOrderId);
        }
    }, [purchaseOrderId]);

    useEffect(() => {
        if (props.inventoryVendors && props.inventoryVendors.length > 0) {
            let tempSelectList = []
            for (const i in props.inventoryVendors) {
                let tempSelectObj = {}
                let obj = props.inventoryVendors[i].attributes
                tempSelectObj.id = props.inventoryVendors[i].id
                tempSelectObj.companyName = obj.companyName ?? ""
                tempSelectObj.value = props.inventoryVendors[i].id
                tempSelectObj.label = obj.companyName ?? ""
                tempSelectObj.parseObject = props.inventoryVendors[i]
                if ((matchEditItem || franchiseMatchEditItem) && props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId && props.selectedInventoryPurchaseOrder.attributes.vendorId == tempSelectObj.id) {
                    setVendor(tempSelectObj);
                }
                tempSelectList.push(tempSelectObj)
            }
            setVendorList(tempSelectList)
        }
    }, [props.inventoryVendors, props.selectedInventoryPurchaseOrder]);


    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let tempSelectList = []
            for (const i of props.inventoryItems) {
                tempSelectList.push(getSelectionObject(i))
            }
            setItemList(tempSelectList)
        }
    }, [props.inventoryItems])

    const getSelectionObject = (inventoryItem) => {
        let tempSelectObj = {}
        let obj = inventoryItem.attributes
        tempSelectObj.id = inventoryItem.id
        tempSelectObj.name = obj.name ?? ""
        tempSelectObj.value = inventoryItem.id
        tempSelectObj.label = obj.name ?? ""
        tempSelectObj.parseObject = inventoryItem
        tempSelectObj.isMatrix = obj.isMatrix
        return tempSelectObj
    }

    function handleNewItem() {
        let items = [...tableData]
        let newItem = {
            itemId: uuidv4(),
            item: undefined,
            itemError: false,
            units: undefined,
            quantity: undefined,
            price: undefined,
            quantityError: false,
            priceError: false,
            parseObject: undefined,
        }
        items.push(newItem)
        setTableData(items)
    }

    const handleRemoveItem = (item) => {
        let newItems = tableData.filter((k) => k.itemId !== item.itemId);
        setTableData(newItems);
    }

    function formatDate(d) {
        if (d === "") {
            return ""
        } else {
            return Moment(d).format("YYYY-MM-DD");
        }
    }

    useEffect(() => {
        // console.log("purchase orders", props.selectedInventoryPurchaseOrder);
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId) {
            // setVendor(getVendorName(props.selectedInventoryPurchaseOrder.attributes.vendorId));
            setDate(formatDate(props.selectedInventoryPurchaseOrder.attributes.createdOn));

            if (props.selectedInventoryPurchaseOrder.attributes.status == 1) {
                setIsDesabled(true);
            }

        }
    }, [props.selectedInventoryPurchaseOrder]);


    useEffect(() => {
        // console.log("Purchase order items", props.selectedInventoryPurchaseOrderItems);
        if ((matchEditItem || franchiseMatchEditItem) && props.selectedInventoryPurchaseOrderItems && props.selectedInventoryPurchaseOrderItems.length > 0) {
            let items = []
            for (const si of props.selectedInventoryPurchaseOrderItems) {
                if (si.attributes.purchaseOrderId == purchaseOrderId) {
                    let newItem = {
                        itemId: uuidv4(),
                        item: getSelectionItemFromId(si.attributes.itemId),
                        itemError: false,
                        units: si.attributes.unit,
                        quantity: si.attributes.quantity,
                        price: si.attributes.costPrice / si.attributes.quantity,
                        tax: si.attributes.tax,
                        finalPrice: si.attributes.costPrice ?? 0,
                        quantityError: false,
                        priceError: false,
                        subItems: si.attributes.subItemsMatrix,
                        parseObject: si,
                    }
                    items.push(newItem)
                }

            }
            updatePurchaseOrderCost(items)
            setTableData(items)
        }

    }, [props.selectedInventoryPurchaseOrderItems])

    const getSelectionItemFromId = (id) => {

        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return getSelectionObject(item)
            }
        }
    }

    const handleApproveOrder = () => {
        console.log("1")
        Parse.Cloud.run("function_AprovePurchaseOrder", {
            purchaseOrderId: purchaseOrderId
        }).then((response) => {
            console.log('response', response)
            if (response.state == 1) {
                setIsDesabled(true);
                setApproveAlert(false)
            } else {

            }
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    console.log("props.inventoryWarehouses", props.inventoryWarehouses[0])

    const handelSave = () => {


        let canSave = true;

        let newList = []
        for (let i of tableData) {
            if (!i.item) {
                i.itemError = true;
                canSave = false
            }

            if (!i.quantity || i.quantity < 0) {
                i.quantityError = true;
                canSave = false
            }
            if (!i.price || i.price < 0) {
                i.priceError = true;
                canSave = false
            }

            newList.push(i)
        }
        setTableData(newList);

        if (!vendor) {
            setVendorListError(true);
            canSave = false;
        }

        if (!date) {
            setDateError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }

        console.log("tableDate", tableData)

        setSaveLoding(true)
        const InventoryPurchaseOrders = Parse.Object.extend("InventoryPurchaseOrders");
        let purchaseOrder = new InventoryPurchaseOrders();
        if ((matchEditItem || franchiseMatchEditItem)) {
            purchaseOrder.id = props.selectedInventoryPurchaseOrder.id
        } else {
            if (franchiseId) {
                purchaseOrder.set("ownerType", 'franchise');
                purchaseOrder.set("ownerId", franchiseId);
            } else {
                purchaseOrder.set("ownerType", 'school');
                purchaseOrder.set("ownerId", schoolId);
            }
            purchaseOrder.set('createdBy', Parse.User.current().attributes.teacherId);
            if (date) {
                purchaseOrder.set("createdOn", new Date(date));
            } else {
                purchaseOrder.set("createdOn", new Date());
            }
            purchaseOrder.set("purchaseOrderTerms", props.inventoryWarehouses[0] && props.inventoryWarehouses[0].attributes.purchaseOrderTerms)
        }

        if (!purchaseOrder.attributes.warehouseId && props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            purchaseOrder.set("warehouseId", props.inventoryWarehouses[0].id);
        }
        purchaseOrder.set("vendorId", vendor.value);
        purchaseOrder.set('costPrice', parseFloat(purchaseOrderCost));
        purchaseOrder.save().then(
            (result) => {
                setSaveLoding(false)
                result.pin()
                console.log(result)
                if ((matchEditItem || franchiseMatchEditItem)) {
                    let itemObjects = [];
                    let exItems = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventoryPurchaseOrderItems = Parse.Object.extend("InventoryPurchaseOrderItems");
                        let purchaseOrderItem = new InventoryPurchaseOrderItems();
                        if (tableData[i].parseObject) {
                            purchaseOrderItem.id = tableData[i].parseObject.id
                            exItems.push(tableData[i].parseObject.id);
                        } else {
                            purchaseOrderItem.set("purchaseOrderId", result.id);
                            if (franchiseId) {
                                purchaseOrderItem.set("ownerType", 'franchise');
                                purchaseOrderItem.set("ownerId", franchiseId);
                            } else {
                                purchaseOrderItem.set("ownerType", 'school');
                                purchaseOrderItem.set("ownerId", schoolId);
                            }
                        }
                        purchaseOrderItem.set("itemId", tableData[i].item.id);
                        purchaseOrderItem.set("itemName", tableData[i].item.name);
                        purchaseOrderItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        purchaseOrderItem.set("costPrice", parseFloat(tableData[i].finalPrice + ""));
                        purchaseOrderItem.set("tax", parseFloat(tableData[i].tax + ""));
                        purchaseOrderItem.set("unit", tableData[i].units);
                        if (tableData[i].item.isMatrix) {
                            purchaseOrderItem.set("subItemsMatrix", tableData[i].subItems);
                        }
                        purchaseOrderItem.set("order", i);
                        itemObjects.push(purchaseOrderItem);
                    }
                    let deletedItems = [];
                    for (const si of props.selectedInventoryPurchaseOrderItems) {
                        if (!exItems.includes(si.id)) {
                            si.set("isDeleted", true);
                            si.set("deletedBy", Parse.User.current().attributes.teacherId);
                            deletedItems.push(si)
                        }
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        if (deletedItems.length > 0) {
                            Parse.Object.saveAll(deletedItems).then((dResults) => {
                                setSaveLoding(false)
                                Parse.Object.unPinAll(dResults);
                                console.log(dResults);
                                if (matchEditItem || franchiseMatchEditItem) {

                                } else {
                                    window.history.back();
                                }
                            });
                        } else {
                            // console.log(results);
                            if (matchEditItem || franchiseMatchEditItem) {

                            } else {
                                window.history.back();
                            }
                        }
                    });
                } else {
                    let itemObjects = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventoryPurchaseOrderItems = Parse.Object.extend("InventoryPurchaseOrderItems");
                        let purchaseOrderItem = new InventoryPurchaseOrderItems();

                        purchaseOrderItem.set("purchaseOrderId", result.id);
                        if (franchiseId) {
                            purchaseOrderItem.set("ownerType", 'franchise');
                            purchaseOrderItem.set("ownerId", franchiseId);
                        } else {
                            purchaseOrderItem.set("ownerType", 'school');
                            purchaseOrderItem.set("ownerId", schoolId);
                        }
                        purchaseOrderItem.set("itemId", tableData[i].item.id);
                        purchaseOrderItem.set("itemName", tableData[i].item.name);
                        purchaseOrderItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        purchaseOrderItem.set("costPrice", parseFloat(tableData[i].finalPrice + ""));
                        purchaseOrderItem.set("tax", parseFloat(tableData[i].tax + ""));
                        if (tableData[i].item.isMatrix) {
                            purchaseOrderItem.set("subItemsMatrix", tableData[i].subItems);
                        }
                        purchaseOrderItem.set("unit", tableData[i].units);
                        purchaseOrderItem.set("order", i);

                        itemObjects.push(purchaseOrderItem);
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        setSaveLoding(false)
                        Parse.Object.pinAll(results);
                        console.log(results);
                        if (matchEditItem || franchiseMatchEditItem) {

                        } else {
                            window.history.back();
                        }
                    });
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleClick = () => {
        handelSave();
        setApproveAlert(true);
    };

    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let itemIds = []
            for (const si of props.inventoryItems) {
                itemIds.push(si.id)
            }
            props.getInventorySubItemForItemIds(itemIds)
            props.getInventoryItemMatrixForItemIds(itemIds)
        }
    }, [props.inventoryItems])

    const getsubItemList = (itemId,) => {
        console.log("props.inventorySubItems", props.inventorySubItems)
        if (props.inventorySubItems && props.inventorySubItems.length > 0) {
            let subItemList = []
            for (const si of props.inventorySubItems) {
                if (si.attributes.itemId == itemId) {
                    subItemList.push({
                        objectId: si.id,
                        quantity: 0,
                        priceBeforeTax: 0,
                        priceAfterTax: 0,
                        itemId: itemId,
                        matrixSizeId: si.attributes.matrixSizeId,
                        sizeName: getMatrixName(si.attributes.matrixSizeId),
                        matrixColorId: si.attributes.matrixColorId,
                        colorName: getMatrixName(si.attributes.matrixColorId),
                        // subItem: si,
                    })
                }
            }
            return subItemList;
        }
    };

    const getMatrixName = (matrixId) => {
        if (props.inventoryMatrixs && props.inventoryMatrixs.length > 0) {
            let temp = props.inventoryMatrixs.filter(
                (matrix) => matrix.id == matrixId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.value ?? "-")
            }
        }
    }

    const calculateTotalQuantity = (subItems) => {
        return subItems.reduce((total, subItem) => total + (parseInt(subItem.quantity, 10) || 0), 0);
    };

    const calculateTotalFinalPrice = (subItems) => {
        return subItems.reduce((total, subItem) => total + (parseInt(subItem.finalPrice, 10) || 0), 0);
    };

    const updateFinalPrice = (subItem) => {
        return (subItem.quantity || 0) * (subItem.priceAfterTax || 0);
    };

    const updateTableData = (updatedSubItems, itemKey) => {
        const newTotalQuantity = calculateTotalQuantity(updatedSubItems);
        const newTotalFinalPrice = calculateTotalFinalPrice(updatedSubItems);

        const averagePrice = newTotalQuantity > 0 ? (newTotalFinalPrice / newTotalQuantity) : 0;

        let updatedTableData = [...tableData];
        updatedTableData[itemKey] = {
            ...updatedTableData[itemKey],
            subItems: updatedSubItems,
            quantity: updatedTableData[itemKey].item.isMatrix ? newTotalQuantity : updatedTableData[itemKey].quantity,
            finalPrice: newTotalFinalPrice,
            price: averagePrice,
            quantityError: false
        };

        updatePurchaseOrderCost(updatedTableData);
        setTableData(updatedTableData);
    };

    const handleSubItemBeforeTaxPriceChange = (subItem, newPriceBeforeTax, itemKey, subItemKey) => {
        let updatedSubItems = [...tableData[itemKey].subItems];
        const taxRate = tableData[itemKey].tax || 0;

        const priceAfterTax = parseFloat(newPriceBeforeTax) * (1 + taxRate / 100);

        updatedSubItems[subItemKey] = {
            ...updatedSubItems[subItemKey],
            priceBeforeTax: newPriceBeforeTax,
            priceAfterTax: priceAfterTax.toFixed(2),
            finalPrice: updateFinalPrice({
                ...updatedSubItems[subItemKey],
                priceAfterTax
            }).toFixed(2)
        };

        updateTableData(updatedSubItems, itemKey);
    };

    const handleSubItemAftreTaxPriceChange = (subItem, newPriceAfterTax, itemKey, subItemKey) => {
        let updatedSubItems = [...tableData[itemKey].subItems];
        const taxRate = tableData[itemKey].tax || 0;

        const priceBeforeTax = parseFloat(newPriceAfterTax) / (1 + taxRate / 100);

        updatedSubItems[subItemKey] = {
            ...updatedSubItems[subItemKey],
            priceAfterTax: newPriceAfterTax,
            priceBeforeTax: priceBeforeTax.toFixed(2),
            finalPrice: updateFinalPrice({
                ...updatedSubItems[subItemKey],
                priceAfterTax: newPriceAfterTax
            }).toFixed(2)
        };

        updateTableData(updatedSubItems, itemKey);
    };

    const handleSubItemQuantityChange = (subItem, newQuantity, itemKey, subItemKey) => {
        let updatedSubItems = [...tableData[itemKey].subItems];

        updatedSubItems[subItemKey] = {
            ...updatedSubItems[subItemKey],
            quantity: newQuantity,
            finalPrice: updateFinalPrice({
                ...updatedSubItems[subItemKey],
                quantity: newQuantity
            }).toFixed(2)
        };

        updateTableData(updatedSubItems, itemKey);
    };

    const handleTaxChange = (e, itemKey) => {
        let updatedTableData = [...tableData];
        let newTax = parseFloat(e.target.value) || 0;

        updatedTableData[itemKey] = {
            ...updatedTableData[itemKey],
            tax: newTax
        };

        updatedTableData[itemKey].subItems = updatedTableData[itemKey].subItems.map((subItem) => {
            let priceBeforeTax = parseFloat(subItem.priceBeforeTax) || 0;
            let quantity = parseInt(subItem.quantity, 10) || 0;

            let priceAfterTax = priceBeforeTax * (1 + newTax / 100);
            let finalPrice = priceAfterTax * quantity;

            return {
                ...subItem,
                priceAfterTax: priceAfterTax.toFixed(2),
                finalPrice: finalPrice.toFixed(2)
            };
        });

        let totalFinalPrice = updatedTableData[itemKey].subItems.reduce((acc, subItem) => {
            return acc + parseFloat(subItem.finalPrice);
        }, 0);

        updatedTableData[itemKey] = {
            ...updatedTableData[itemKey],
            finalPrice: totalFinalPrice.toFixed(2)
        };

        updatePurchaseOrderCost(updatedTableData);
        setTableData(updatedTableData);
    };

    function updatePurchaseOrderCost(newList) {
        let purchaseOrderCost = 0;
        for (let i of newList) {
            if (i.finalPrice) {
                purchaseOrderCost = purchaseOrderCost + i.finalPrice;
            }
        }
        console.log("purchaseOrderCost", purchaseOrderCost)
        setPurchaseOrderCost(purchaseOrderCost);
    }

    const calculateFinalPrice = (item) => {
        const quantity = parseFloat(item.quantity) || 0;
        const price = parseFloat(item.price) || 0;
        const tax = parseFloat(item.tax) || 0;

        return (quantity * price * (1 + tax / 100)).toFixed(2);
    };


    console.log("tableData", tableData)
    console.log("purchaseOrderCost", purchaseOrderCost)


    return (
        <div className="page-content">
            <div>
                <Row>
                    <Row className="d-flex align-items-start">
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    window.history.back();
                                }}
                            >
                                <ul className="list-unstyled">
                                    <div className="">
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className="m-2">
                                <h4>
                                    {(matchEditItem || franchiseMatchEditItem)
                                        ? "Edit Purchase Order"
                                        : "New Purchase Order"}
                                </h4>
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {(matchEditItem || franchiseMatchEditItem) && (
                                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                    <Link
                                        to={
                                            matchEditItem
                                                ? `/print-purchase-order/${schoolId}/${purchaseOrderId}`
                                                : `/franchise-print-purchase-order/${franchiseId}/${purchaseOrderId}`
                                        }
                                    >
                                        <Button
                                            color="success"
                                            style={{ float: "right" }}
                                            className="btn waves-effect waves-light float-right  m-2"
                                        >
                                            Print
                                        </Button>
                                    </Link>
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <AvForm className="needs-validation">
                                    <Row>
                                        <Col>
                                            <FormGroup className="position-relative">
                                                <Label>Select vendor:</Label>
                                                <Select
                                                    onChange={(e) => {
                                                        setVendor(e);
                                                        setVendorListError(false);
                                                    }}
                                                    value={vendor}
                                                    options={vendorList}
                                                    className="selectVendors"
                                                    classNamePrefix="purchaseVendor"
                                                    isDisabled={isDisabled}
                                                />
                                                {vendorListError && (
                                                    <small className="text-danger">
                                                        Please select vendor
                                                    </small>
                                                )}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>Date :</Label>
                                                <AvField
                                                    type="date"
                                                    name="date"
                                                    className="inner form-control"
                                                    placeholder="dd/mm/yyyy"
                                                    onChange={(e) => {
                                                        setDate(e.target.value);
                                                        setDateError(false);
                                                    }}
                                                    value={date}
                                                    disabled={isDisabled}
                                                />
                                                {dateError && (
                                                    <small className="text-danger">Please select date</small>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </AvForm>
                                {tableData && tableData.length > 0 && (
                                    <Row>
                                        <Col>
                                            <div className="mt-1">
                                                <div
                                                    className="border rounded p-3 mb-1"
                                                    style={{ backgroundColor: "white" }}
                                                >
                                                    <Row
                                                        style={{
                                                            height: "8px",
                                                            paddingBottom: "20px",
                                                        }}
                                                    >
                                                        <Col lg={1}>
                                                            <Label className="mb-1 font-size-14">#</Label>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Label className="mb-1 font-size-14">All Items</Label>
                                                        </Col>
                                                        <Col lg={1}>
                                                            <Label className="mb-1 font-size-14">Unit</Label>
                                                        </Col>
                                                        <Col>
                                                            <Label className="mb-1 font-size-14">Quantity</Label>
                                                        </Col>
                                                        <Col>
                                                            <Label className="mb-1 font-size-14">Tax</Label>
                                                        </Col>
                                                        <Col>
                                                            <Label className="mb-1 font-size-14">Price</Label>
                                                        </Col>
                                                        <Col>
                                                            <Label className="mb-1 font-size-14">Final Price</Label>
                                                        </Col>
                                                        <Col>
                                                            <Label className="mb-1 font-size-14">Show Matrix</Label>
                                                        </Col>
                                                    </Row>
                                                </div>{console.log("tableData", tableData)}
                                                {tableData.map((item, itemKey) => (
                                                    <Card
                                                        className="mb-1 bg-whites"
                                                        key={itemKey}
                                                    >
                                                        <CardBody>
                                                            <Row>
                                                                <Col lg={1}>
                                                                    <div className="d-flex">
                                                                        {itemKey + 1}
                                                                    </div>
                                                                </Col>
                                                                <Col lg={3}>
                                                                    <FormGroup className="position-relative">
                                                                        <Select
                                                                            style={{ width: "14rem" }}
                                                                            onChange={(selectedOption) => {
                                                                                let tempItem = { ...item };
                                                                                tempItem.units =
                                                                                    selectedOption.parseObject.attributes.unit;
                                                                                tempItem.item = selectedOption;
                                                                                tempItem.itemError = false;

                                                                                let newList = [];
                                                                                for (let i of tableData) {
                                                                                    if (i.itemId == item.itemId) {
                                                                                        newList.push(tempItem);
                                                                                    } else {
                                                                                        newList.push(i);
                                                                                    }
                                                                                }
                                                                                const updatedItemList = itemList.filter(
                                                                                    (item) => item.value !== selectedOption.value
                                                                                );

                                                                                setItemList(updatedItemList);
                                                                                setTableData(newList);
                                                                            }}
                                                                            value={item.item}
                                                                            id="itemList"
                                                                            options={itemList}
                                                                            className="selectItems"
                                                                            classNamePrefix="purchaseItem"
                                                                            isDisabled={isDisabled || item && item.parseObject}
                                                                        />
                                                                        {item.itemError && (
                                                                            <small className="text-danger">
                                                                                Please select item
                                                                            </small>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg={1}>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="units"
                                                                            placeholder="Units"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={item.units}
                                                                            id="units"
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="quantity"
                                                                            placeholder="No's"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="quantity"
                                                                            value={item.quantity}
                                                                            disabled={item.item && item.item.isMatrix || isDisabled}
                                                                            onChange={(e) => {
                                                                                if (!item.item.isMatrix) {
                                                                                    let updatedTableData = [...tableData];
                                                                                    updatedTableData[itemKey] = {
                                                                                        ...updatedTableData[itemKey],
                                                                                        quantity: e.target.value,
                                                                                        finalPrice: calculateFinalPrice({
                                                                                            ...updatedTableData[itemKey],
                                                                                            quantity: e.target.value,
                                                                                        }),
                                                                                        quantityError: false,
                                                                                    };
                                                                                    updatePurchaseOrderCost(updatedTableData);
                                                                                    setTableData(updatedTableData);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {item.quantityError && (
                                                                            <small className="text-danger">Please Enter Quantity</small>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="Tax"
                                                                            placeholder="Tax in %"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="Tax"
                                                                            value={item.tax}
                                                                            disabled={isDisabled}
                                                                            onChange={(e) => {
                                                                                if (!item.item.isMatrix) {
                                                                                    let updatedTableData = [...tableData];
                                                                                    updatedTableData[itemKey] = {
                                                                                        ...updatedTableData[itemKey],
                                                                                        tax: e.target.value,
                                                                                        finalPrice: calculateFinalPrice({
                                                                                            ...updatedTableData[itemKey],
                                                                                            tax: e.target.value,
                                                                                        }),
                                                                                    };
                                                                                    updatePurchaseOrderCost(updatedTableData);
                                                                                    setTableData(updatedTableData);
                                                                                } else {
                                                                                    handleTaxChange(e, itemKey);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="Price"
                                                                            placeholder="Price"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="Price"
                                                                            value={item.price ? item.price : item.price}
                                                                            disabled={item.item && item.item.isMatrix || isDisabled}
                                                                            onChange={(e) => {
                                                                                if (!item.item.isMatrix) {
                                                                                    let updatedTableData = [...tableData];
                                                                                    updatedTableData[itemKey] = {
                                                                                        ...updatedTableData[itemKey],
                                                                                        price: e.target.value,
                                                                                        finalPrice: calculateFinalPrice({
                                                                                            ...updatedTableData[itemKey],
                                                                                            price: e.target.value,
                                                                                        }),
                                                                                        priceError: false,
                                                                                    };
                                                                                    updatePurchaseOrderCost(updatedTableData);
                                                                                    setTableData(updatedTableData);
                                                                                }
                                                                            }}
                                                                        />
                                                                        {item.priceError && (
                                                                            <small className="text-danger">Please Enter Price</small>
                                                                        )}
                                                                    </FormGroup>
                                                                </Col>

                                                                <Col>
                                                                    <FormGroup>
                                                                        <Input
                                                                            name="finalPrice"
                                                                            placeholder="Final Price"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="finalPrice"
                                                                            value={item.finalPrice}
                                                                            disabled={true}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    {item.item && item.item.isMatrix && (
                                                                        <p
                                                                            style={{ cursor: "pointer" }}
                                                                            className="text-primary"
                                                                            onClick={() => {
                                                                                if (showItemMatrix === item.itemId) {
                                                                                    setShowItemMatrix(false);
                                                                                } else {
                                                                                    setShowItemMatrix(item.itemId);
                                                                                    if(matchAdd || franchiseMatchAdd){
                                                                                        let updatedTableData = [...tableData];
                                                                                        updatedTableData[itemKey] = {
                                                                                            ...updatedTableData[itemKey],
                                                                                            subItems: getsubItemList(item.item.id),
                                                                                        };
                                                                                        setTableData(updatedTableData);
                                                                                    }
                                                                                }
                                                                            }}
                                                                        >
                                                                            <u>
                                                                                {showItemMatrix === item.itemId
                                                                                    ? "Hide Matrix"
                                                                                    : "Show Matrix"}
                                                                            </u>
                                                                        </p>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                            <Collapse
                                                                isOpen={showItemMatrix === item.itemId && item.item.isMatrix}
                                                            >
                                                                <div className="m-3">
                                                                    {item && item.subItems && item.subItems.length > 0 && (
                                                                        <Table className="table mb-0 table-bordered">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ width: "10%", textAlign: "center" }}>
                                                                                        Sl No
                                                                                    </th>
                                                                                    {item.subItems.some((sub) => sub.matrixColorId) && (
                                                                                        <th style={{ width: "20%", textAlign: "center" }}>
                                                                                            Color
                                                                                        </th>
                                                                                    )}
                                                                                    {item.subItems.some((sub) => sub.matrixSizeId) && (
                                                                                        <th style={{ width: "20%", textAlign: "center" }}>
                                                                                            Size
                                                                                        </th>
                                                                                    )}
                                                                                    <th className="text-center">Quantity</th>
                                                                                    <th className="text-center">Price Before Tax</th>
                                                                                    <th className="text-center">Price After Tax</th>
                                                                                    <th className="text-center">Final Price</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {item.subItems.map((subItem, subItemKey) => (
                                                                                    <tr key={subItemKey}>
                                                                                        <td style={{ width: "10%", textAlign: "center" }}>
                                                                                            {subItemKey + 1}
                                                                                        </td>
                                                                                        {item.subItems.some((sub) => sub.matrixColorId) && (
                                                                                            <td style={{ width: "20%", textAlign: "center" }}>
                                                                                                <h6>
                                                                                                    {subItem.matrixColorId
                                                                                                        ? subItem.colorName
                                                                                                        : "-"}
                                                                                                </h6>
                                                                                            </td>
                                                                                        )}
                                                                                        {item.subItems.some((sub) => sub.matrixSizeId) && (
                                                                                            <td style={{ width: "20%", textAlign: "center" }}>
                                                                                                <h6>
                                                                                                    {subItem.matrixSizeId
                                                                                                        ? subItem.sizeName
                                                                                                        : "-"}
                                                                                                </h6>
                                                                                            </td>
                                                                                        )}
                                                                                        <td>
                                                                                            <Input
                                                                                                name="quantity"
                                                                                                placeholder="Quantity"
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="quantity"
                                                                                                value={subItem.quantity || ""}
                                                                                                onChange={(e) => {
                                                                                                    handleSubItemQuantityChange(
                                                                                                        subItem,
                                                                                                        e.target.value,
                                                                                                        itemKey,
                                                                                                        subItemKey
                                                                                                    );
                                                                                                }}
                                                                                                disabled={isDisabled}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input
                                                                                                name="Price"
                                                                                                placeholder="Price"
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="price"
                                                                                                value={subItem.priceBeforeTax || ""}
                                                                                                onChange={(e) => {
                                                                                                    handleSubItemBeforeTaxPriceChange(
                                                                                                        subItem,
                                                                                                        e.target.value,
                                                                                                        itemKey,
                                                                                                        subItemKey
                                                                                                    );
                                                                                                }}
                                                                                                disabled={isDisabled}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input
                                                                                                name="Price"
                                                                                                placeholder="Price"
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="price"
                                                                                                value={subItem.priceAfterTax || ""}
                                                                                                onChange={(e) => {
                                                                                                    handleSubItemAftreTaxPriceChange(
                                                                                                        subItem,
                                                                                                        e.target.value,
                                                                                                        itemKey,
                                                                                                        subItemKey
                                                                                                    );
                                                                                                }}
                                                                                                disabled={isDisabled}
                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <Input
                                                                                                name="Final Price"
                                                                                                placeholder="Final Price"
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                id="finalPrice"
                                                                                                value={
                                                                                                    subItem.finalPrice
                                                                                                        ? subItem.finalPrice
                                                                                                        : subItem.finalPrice || ""
                                                                                                }
                                                                                                disabled={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </Table>
                                                                    )}
                                                                </div>
                                                            </Collapse>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                {!isDisabled && saveLoding ? (
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        className="waves-effect float-right m-2"
                                                    >
                                                        <i className="bx bx-hourglass bx-spin font-size-16"></i>
                                                        Saving...
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="submit"
                                                        className="btn waves-effect waves-light float-right  m-2"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            handelSave(e.target.value);
                                                        }}
                                                    >
                                                        SAVE
                                                    </Button>
                                                )}
                                                {((matchAdd || franchiseMatchAdd) && !isDisabled)  && (
                                                    <Button
                                                        color="success"
                                                        className="btn waves-effect waves-light float-right  m-2"
                                                        onClick={() => {
                                                            handleNewItem();
                                                        }}
                                                    >
                                                        Add Items<i className="mdi mdi-plus mr-1" />
                                                    </Button>
                                                )}
                                                {((matchEditItem || franchiseMatchEditItem) && !isDisabled) && (
                                                    <Button
                                                        color="success"
                                                        className="btn waves-effect waves-light float-right  m-2"
                                                        onClick={() => {
                                                            setApproveAlert(true);
                                                        }}
                                                    >
                                                        Approve Order
                                                    </Button>
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-start">
                                                {isDisabled && (
                                                    <h5 className="text-success d-flex justify-content-start">
                                                        This purchese has been approved and it can not be changed
                                                        further
                                                    </h5>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {
                approveAlert && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Approve"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        title="Alert!"
                        onCancel={() => {
                            setApproveAlert(false);
                        }}
                        onConfirm={() => handleApproveOrder()}
                    >
                        <div>
                            {"Are you sure you want to Approve,"}
                            <br />
                            {"On Approving this purchese order stock will be added to the inventory stock"}
                        </div>
                    </SweetAlert>
                )
            }
        </div >
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryVendors } = state.InventoryVendor;
    const { inventoryItems, inventorySubItems, inventoryMatrixs } = state.InventoryItems;
    const { selectedInventoryPurchaseOrder } = state.InventoryPurchaseOrder;
    const { selectedInventoryPurchaseOrderItems } = state.InventoryPurchaseOrderItem;
    const { inventoryWarehouses } = state.InventoryWarehouse;
    return { selectedSchool, inventoryVendors, inventoryItems, selectedInventoryPurchaseOrder, selectedInventoryPurchaseOrderItems, inventoryWarehouses, inventorySubItems, inventoryMatrixs };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryVendorForOwnerId, getInventoryItemForOwnerId, setSelectedPurchaseOrder, setSelectedPurchaseOrderItems,
    getInventoryWarehouseForOwnerId, getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds
})(NewPurchaseOrder)));
