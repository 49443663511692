import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "./Breadcrumbs";

import {
  getClassroomsForSchoolId,
  createClassForASchool,
  setSelecetdSchool,
  getAllKids,
} from "../../store/actions";
import AddClass from "./addClass";
import Image from "../../assets/images/getStartedImages/classroomInitial.svg";

import ListView from "./listView";

const AllClasses = (props) => {
  // const [activeTab, setactiveTab] = useState(2);
  const [classrooms, classroomsOps] = useState([]);
  const [modal_standard, modal_standardOps] = useState(false);
  

  const match = matchPath(props.history.location.pathname, {
    path: "/allClasses/:id",
    exact: true,
    strict: false,
  });
  const schoolId = match.params.id;
  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    props.getClassroomsForSchoolId(schoolId);
    props.getAllKids(schoolId)
  }, [schoolId]);

  useEffect(() => {
    if (props.classrooms) {
      let tempClass = [];
      let classIds = [];
      for (const val of props.classrooms) {
        if (val.attributes.isDeleted) {
          break;
        }
        let temp = {};
        temp.id = val.id;
        temp.obj = val;
        temp.name = val.attributes.Name;
        temp.schoolId = val.attributes.SchoolID;
        temp.classType = val.attributes.classroomType;
        temp.classLevel = val.attributes.classLevel;
        temp.classCss =
          val.attributes.classroomType === 2 ? "text-danger" : "text-primary";
        temp.kidCount = "-";
        temp.kidIds = [];
        tempClass.push(temp);
        classIds.push(val.id);
      }
      let tempClassSort = [];

      tempClassSort = tempClass.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      let daycareFirst = [];
      for (const val of tempClassSort) {
        if (val.classType === 2) {
          daycareFirst.unshift(val);
        } else {
          daycareFirst.push(val);
        }
      }

      // classroomsOps(daycareFirst);
      updateKidsCount(daycareFirst);
      
    }
  }, [props.classrooms]);
  useEffect(() => {
    updateKidsCount();
  }, [props.kids]);

  const updateKidsCount = (list ) => {
    let tempClassrooms = [];
    if (list) {
      tempClassrooms = [...list];
    } else {
      tempClassrooms = [...classrooms];
    }
    if (props.kids && tempClassrooms && tempClassrooms.length > 0) {
      for (const val of tempClassrooms) {
        for (const valk of props.kids) {
          if (val.id === valk.attributes.ClassRoomID) {
            if (!val.kidIds.includes(valk.id)) {
              val.kidIds.push(valk.id);
            }
          }
          if (val.id === valk.attributes.daycareClassId) {
            if (!val.kidIds.includes(valk.id)) {
              val.kidIds.push(valk.id);
            }
          }
        }
        val.kidCount = val.kidIds.length;
      }  
    }
    classroomsOps(tempClassrooms);
  }

  const tog_standard = () => {
    modal_standardOps(!modal_standard);
  };
 
  return (
    <React.Fragment>
      <AddClass modal_standard={modal_standard} tog_standard={tog_standard} />
      
      <Container fluid>
        <div className="page-content">
          <Breadcrumbs title="Classrooms" tog_standard={tog_standard} />
          {classrooms != null && classrooms.length > 0 && (
            <ListView classList={classrooms} />
          )}
          {classrooms.length === 0 && (
            <Card>
              <CardBody>
                <div className="text-center">
                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="maintenance-img">
                        <img
                          src={Image}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                    </Col>
                  </Row>
                  <h3 className="mt-5">
                    {props.t("Create a new class for your School")}
                  </h3>
                  <p>{props.t("create a new class just in few steps")}.</p>

                  <button onClick={tog_standard} className="btn btn-primary">
                    {props.t("Add Class")}
                  </button>
                </div>
              </CardBody>
            </Card>
          )}
        </div>
      </Container>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { selectedSchool } = state.School;
  return { classrooms, kids, selectedSchool };
};

export default connect(mapStatetoProps, {
  getClassroomsForSchoolId,
  createClassForASchool,
  setSelecetdSchool,
  getAllKids,
})(withRouter(withNamespaces()(AllClasses)));
