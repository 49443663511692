import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as MoneyTransactionHelper from "../../../helpers/ParseHelpers/moneyTransactions_helper";
import * as InvoiceHelper from "../../../helpers/ParseHelpers/invoice_helper"
import * as actionTypes from "./actionTypes";
import * as Constants from "../../../Constents";
import * as MoneyTransactionActions from "./actions";
import * as InvoiceActions from "../invoice/actions";

function* getAMoneyTransaction({ payload: { id } }) {
  try {
    const response = yield call(
      MoneyTransactionHelper.getMoneyTransactionFromParse,
      id,
      false
    );
    yield put(MoneyTransactionActions.updateMoneyTransaction(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAMoneyTransaction({ payload: { moneyTransactionId } }) {
  try {
    const response = yield call(
      MoneyTransactionHelper.deleteMoneyTransactionFromParse,
      moneyTransactionId
    );
    if (response) {
      const iResponse = yield call(InvoiceHelper.getInvoiceFromParse, response.attributes.invoiceId, false);
      if (iResponse) {
        yield put(InvoiceActions.updateInvoice(iResponse));
        iResponse.pin();
      }
      yield put(MoneyTransactionActions.deleteMoneyTransaction(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getMoneyTransactionForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.MONEY_TRANSACTION_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      MoneyTransactionHelper.getMoneyTransactionForSchoolId,
      schoolId,
      null,
      true
    );
    yield put(MoneyTransactionActions.updateMoneyTransactions(localResponse));

    const serverResponse = yield call(
      MoneyTransactionHelper.getMoneyTransactionForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(
        MoneyTransactionActions.updateMoneyTransactions(serverResponse)
      );
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* receivePayemntByFunction({ payload: { inputParam, invoiceItems } }) {
  try {
    let response = yield call(
      MoneyTransactionHelper.receivePaymentByFunction,
      inputParam,
      invoiceItems
    );
    // response.pin();
    if (response) {
      yield put(
        MoneyTransactionActions.recivePaymentByFunctionSuccess(response)
      );
    }
  } catch (error) {}
}

function* getTriansactionForId({ payload: { id } }) {
  try {
    if (id) {
      let key = Constants.MONEY_TRANSACTION_UPDATED_TIME + "_" + id;
      let updatedTime = localStorage.getItem(key);
      let newTime = new Date();
      let lResponse = yield call(
        MoneyTransactionHelper.getTransactionForIdParse,
        id,
        null,
        true
      );
      yield put(MoneyTransactionActions.updateMoneyTransactions(lResponse));

      let sResponse = yield call(
        MoneyTransactionHelper.getTransactionForIdParse,
        id,
        updatedTime,
        false
      );
      if (sResponse.length > 0) {
        localStorage.setItem(key, newTime);
      } else {
        sResponse = yield call(
          MoneyTransactionHelper.getTransactionForIdParse,
          id,
          null,
          false
        );
      }

      if (sResponse.length > 0) {
        yield put(
          MoneyTransactionActions.updateMoneyTransactionsUpdate(sResponse)
        );
        Parse.Object.pinAll(sResponse);
      }
    }
  } catch (error) {}
}

function* verifyTriansactionById({ payload: { id } }) {
  try {
    if (id) {
      let response = yield call(
        MoneyTransactionHelper.updateVerifyTrinansaction,
        id
      );
      if (response) {
        yield put(MoneyTransactionActions.setTriansaction(response));
        yield put(MoneyTransactionActions.removeFromPendingTriansactions(response));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteTriansactionForId({ payload: { id } }) {
  try {
    if (id) {
      const response = yield call(
        MoneyTransactionHelper.deleteTriansactionForId,
        id
      );
      if (response) {
        const iResponse = yield call(InvoiceHelper.getInvoiceFromParse, response.attributes.invoiceId, false);
      if (iResponse) {
        yield put(InvoiceActions.updateInvoice(iResponse));
        iResponse.pin();
      }
        yield put(MoneyTransactionActions.setTriansaction(response));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

function* getTransactionForFilter({ payload: { filters } }) {
  try {
    let response = yield call(
      MoneyTransactionHelper.getTransactionForFiltersParse,
      filters,
      true
    );
    if (response.length > 0) {
      yield put(MoneyTransactionActions.updateMoneyTransactions(response));
    }
    response = [];
    response = yield call(
      MoneyTransactionHelper.getTransactionForFiltersParse,
      filters,
      false
    );
    if (response.length > 0) {
      Parse.Object.pinAll(response);
    }
    yield put(MoneyTransactionActions.updateMoneyTransactions(response));
  } catch (err) {
    console.log(err);
  }
}
function* getVerifiedForPendingInvoices({ payload: { ids } }) {
  try {
    let response = yield call(
      MoneyTransactionHelper.getVerifiedForPendingInvoices,
      ids
    );
    yield put(MoneyTransactionActions.setVerifiedForPendingInvoice(response));
  } catch (error) {
    console.error(error);
  }
}

function* getTriansactionCountForFilter({ payload: { filters } }) {
  try {
    yield put(MoneyTransactionActions.setTriansactionCountForFilter(0));
    let response = yield call(
      MoneyTransactionHelper.getTriansactionCountForFilter,
      filters
    );
    yield put(MoneyTransactionActions.setTriansactionCountForFilter(response));
  } catch (err) {
    console.log(err);
  }
}

function* getTriansactionForInvoiceIds({ payload: { invoiceIds } }) {
  try {
    const response = yield call(
      MoneyTransactionHelper.getTriansactionForInvoiceIds,
      invoiceIds
    );
    yield put(MoneyTransactionActions.setTriansactionForInvoiceIds(response));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetMoneyTransaction() {
  yield takeEvery(actionTypes.GET_MONEY_TRANSACTION, getAMoneyTransaction);
}

export function* watchDeleteMoneyTransaction() {
  yield takeEvery(
    actionTypes.DELETE_MONEY_TRANSACTION,
    deleteAMoneyTransaction
  );
}

export function* watchGetMoneyTransactionForSchoolId() {
  yield takeEvery(
    actionTypes.GET_MONEY_TRANSACTIONS,
    getMoneyTransactionForSchoolId
  );
}

export function* watchReceivePayemntByFunction() {
  yield takeEvery(
    actionTypes.RECEIVE_PAYMENT_BY_FUNCTION,
    receivePayemntByFunction
  );
}

export function* watchGetTriansactionsForId() {
  yield takeEvery(
    actionTypes.GET_TRANSACTION_FOR_FOR_INVOICE_ID,
    getTriansactionForId
  );
}

export function* watchVerifyTriansactionForId() {
  yield takeEvery(
    actionTypes.VERIFY_TRIANSACTION_BY_ID,
    verifyTriansactionById
  );
}

export function* watchDeleteTriansactionForId() {
  yield takeEvery(
    actionTypes.DELETE_TRANSACTION_BY_ID,
    deleteTriansactionForId
  );
}

export function* watchGetTransactionForFilter() {
  yield takeEvery(
    actionTypes.GET_ALL_TRIANSACTION_FOR_FILTER,
    getTransactionForFilter
  );
}
export function* watchGetVerifiedForPendingInvoices() {
  yield takeEvery(
    actionTypes.GET_VERIFIED_STATUS_FOR_INVOICES,
    getVerifiedForPendingInvoices
  );
}
export function* watchGetTransactionCountForFilter() {
  yield takeEvery(
    actionTypes.GET_ALL_TRIANSACTION_COUNT_FOR_FILTER,
    getTriansactionCountForFilter
  );
}

export function* watchGetTriansactionForInvoiceIds() {
  yield takeEvery(
    actionTypes.GET_TRIANSACTION_FOR_INVOICE_IDS,
    getTriansactionForInvoiceIds
  );
}

function* MoneyTransactionSaga() {
  yield all([
    fork(watchGetMoneyTransaction),
    fork(watchDeleteMoneyTransaction),
    fork(watchGetMoneyTransactionForSchoolId),
    fork(watchReceivePayemntByFunction),
    fork(watchGetTriansactionsForId),
    fork(watchVerifyTriansactionForId),
    fork(watchDeleteTriansactionForId),
    fork(watchGetTransactionForFilter),
    fork(watchGetVerifiedForPendingInvoices),
    fork(watchGetTransactionCountForFilter),
    fork(watchGetTriansactionForInvoiceIds),
  ]);
}
export default MoneyTransactionSaga;
